import React from 'react';

import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import Indent from 'components/reusable/indent';
import Tip from 'components/complex/tip';

const SignUpAdditionalStepsHeading = ({ index, label, tip, required = false }) => {
  const size = [{ maxWidth: 768, size: 16 }, { size: 20 }];
  return (
    <Flex jc="flex-start">
      <Text size={size} weight={500} color={colors.primary[500]}>
        0{index + 1}.{' '}
      </Text>
      <Indent right={6} />

      <Text size={size} weight={500} color={colors.gray[700]}>
        {label}
      </Text>

      {required && (
        <Text size={size} weight={500} color={colors.primary[500]}>
          *
        </Text>
      )}

      {tip && (
        <>
          <Indent right={6} />
          <Tip>{tip}</Tip>
        </>
      )}
    </Flex>
  );
};

export default SignUpAdditionalStepsHeading;
