import React, { useCallback, useContext, useState } from 'react';
import queryString from 'query-string';

import {
  InnerWrapper,
  PrimaryButton,
  Wrapper
} from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';
import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import Error from 'components/reusable/error';
import Indent from 'components/reusable/indent';
import GoogleButton from 'components/complex/google_sso/google_sso';
import SignUpOrSection from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/components/or_section';
import { ModalContext, RootContext } from 'contexts/contexts';
import useGoogleOauth from './hooks/use_google_oauth';
import SignUpAdditionalStepsCloseButton from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_additional_steps_close_button';
import Link from 'components/reusable/link';
import { modalEnum } from 'components/modals/v2/modal';

const SignInModal = ({ onClose }) => {
  const modalContext = useContext(ModalContext);
  const rootContext = useContext(RootContext);
  const [error, setError] = useState('');
  const { onOauthSuccess } = useGoogleOauth({ rootContext, setError, onClose });
  const q = queryString.parse(window.location.search);

  const onShowSignInByEmailModal = useCallback(
    () => modalContext.onOpenModal(modalEnum.signInByEmail),
    []
  );
  const onShowSignUp = useCallback(
    () => modalContext.onOpenModal(modalEnum.signUp),
    []
  );

  return (
    <Wrapper>
      <SignUpAdditionalStepsCloseButton onClick={onClose} />

      <InnerWrapper>
        <Text
          textAlign="center"
          size={24}
          weight={500}
          color={colors.gray[700]}
        >
          LOG IN
        </Text>
        <Indent top={40} />

        <GoogleButton
          label="Log In with Google"
          onFailure={setError}
          onSuccess={onOauthSuccess}
        />

        <Indent top={12} />
        <Error textAlign="center">{error}</Error>
        <Indent top={16} />
        <SignUpOrSection />
        <Indent top={24} />

        <PrimaryButton onClick={onShowSignInByEmailModal}>
          <Text color={colors.light} size={16} weight={500}>
            Continue with Email
          </Text>
        </PrimaryButton>

        {q.sign_up !== 'false' && (
          <>
            <Indent top={24} />
            <Text textAlign="center" size={14}>
              Don’t have an account?{' '}
              <Link
                size={14}
                color={colors.primary[500]}
                onClick={onShowSignUp}
              >
                Sign Up
              </Link>
            </Text>
          </>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

export default SignInModal;
