const bytesInOneMB = 1024 * 1024;

const FileHelpers = {
  async canvasToFile(canvas, filename) {
    return new Promise(resolve => {
      canvas.toBlob(blob => {
        const file = new File([blob], filename, { type: blob.type });
        resolve(file);
      });
    });
  },

  base64ToFile(base64String, filename) {
    // Split the base64 string to get the mime type and the actual data
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // Convert the base64 string to a byte array
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    // Create a new File object from the byte array
    const file = new File([u8arr], filename, { type: mime });
    return file;
  },

  bytesToKB(blobSizeBytes) {
    if (!blobSizeBytes) return null;
    const bytesInOneKB = 1024;
    return parseFloat((blobSizeBytes / bytesInOneKB).toFixed(1));
  },

  bytesToMB(blobSizeBytes) {
    if (!blobSizeBytes) return null;
    return parseFloat((blobSizeBytes / bytesInOneMB).toFixed(1));
  },

  bytesLabel(blobSizeBytes) {
    if (!blobSizeBytes) return null;
    if (blobSizeBytes > bytesInOneMB) return `${FileHelpers.bytesToMB(blobSizeBytes)} Mb`;
    return `${FileHelpers.bytesToKB(blobSizeBytes)} Kb`;
  },

  async triggerDownload(fileUrl, _fileName) {
    const fileName = _fileName || fileUrl.split('/').at(-1);
    const anchor = document.createElement('a');

    anchor.target = '_blank';
    anchor.href = fileUrl;
    anchor.download = fileName;
    anchor.style.display = 'none';

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
};

export default FileHelpers;
