import React, { useMemo } from 'react';
import { Field, reduxForm } from 'redux-form';

import { colors } from 'helpers/colors';
import { WrapperWithProgressIndent } from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import SignUpAdditionalStepsProgress from './sign_up_additional_steps_progress';
import { StyledField, StyledForm } from './styled';
import SignUpAdditionalStepsButtonsToolbar from './sign_up_additional_steps_buttons_toolbar';
import SignUpAdditionalStepsCloseButton from './sign_up_additional_steps_close_button';
import SignUpAdditionalStepsHeading from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_additional_steps_heading';
import { signUpStepsData } from '../helpers/signup_steps_helper';
import Globals from 'helpers/globals';
import Settings from 'helpers/settings';
import ffHelpers from 'helpers/ff_helpers';
import Error from 'components/reusable/error';

const validate = data => {
  const errors = {};
  if (!data[signUpStepsData.profile.field])
    errors[signUpStepsData.profile.field] = 'Please select profile type';
  return errors;
};

const SignUpProfileStep = ({
  name,
  index,
  progress,
  onClose,
  handleSubmit,
  serverError,
  onBack,
  ...props
}) => {
  const profileOpts = useMemo(() => {
    const categories = Globals.profileCategoriesSignUpV2;
    return categories.map(p => ({ value: p, label: p }));
  }, []);

  const isSubmitDisabled = props.invalid || props.submitting;

  return (
    <StyledForm onSubmit={handleSubmit}>
      <SignUpAdditionalStepsProgress value={progress} />

      <WrapperWithProgressIndent>
        {Settings.signUpAdditionalStepsSkippable ? (
          <SignUpAdditionalStepsCloseButton onClick={onClose} />
        ) : null}

        <Text
          textAlign="center"
          size={24}
          weight={500}
          color={colors.gray[700]}
        >
          Additional Details
        </Text>

        <Indent top={24} />
        <SignUpAdditionalStepsHeading label="What do you do?" index={index} required />
        <Indent top={24} />

        <Field
          name={name}
          type="select"
          validate={ffHelpers.required}
          options={profileOpts}
          placeholder="I'm a..."
          component={StyledField}
        />

        <Indent top={8} />
        <Error textAlign={'center'}>{serverError}</Error>
        <Indent top={36} />
        <SignUpAdditionalStepsButtonsToolbar onBack={onBack} isSubmitDisabled={isSubmitDisabled} />
      </WrapperWithProgressIndent>
    </StyledForm>
  );
};

export default reduxForm({
  initialValues: {
    [signUpStepsData.profile.field]: Globals.profileTypes.designer
  },
  form: signUpStepsData.profile.form,
  destroyOnUnmount: false,
  validate
})(SignUpProfileStep);
