import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { reduxForm, getFormValues } from 'redux-form'

import ZoomAgreementModalLeftPartition
  from 'components/modals/v2/zoom_meeting_agreement_modal/components/zoom_agreement_modal_left_partition'
import ZoomAgreementModalRightPartition
  from 'components/modals/v2/zoom_meeting_agreement_modal/components/zoom_agreement_modal_right_partition'
import AuthHelper from 'helpers/auth_helper'
import Settings from 'helpers/settings'
import ZoomHelpers from 'helpers/zoom_helpers'
import { getDigitalSignature } from 'components/reusable/signature'

const FORM_NAME = 'meetingAgreement'
const MIN_SIGNATURE_LENGTH = 6

const ZoomMeetingAgreementModal = ({ modalProps = {}, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(false)
  const { signer_user_id, zoom_agreement_id } = modalProps
  const fullName = useSelector(state => getFormValues(FORM_NAME)(state))?.full_name || ''
  const submitDisabled = loading || fullName.length < MIN_SIGNATURE_LENGTH || !selected
  const isSigning = !!zoom_agreement_id
  const submitLabel = isSigning ? 'Sign & Close' : 'Send & Close'

  const handleSignAgreement = useCallback(async () => {
    setLoading(true)
    const signature = await getDigitalSignature()

    try {
      await ZoomHelpers.signAgreementAsync(zoom_agreement_id, signature)
      alert('The digital user agreement has been successfully signed! You can now start your Zoom meeting.')
      onClose()
    } catch (err) {
      console.log(err.message)
      alert(err.message || Settings.unknownErrorMessage)
    } finally {
      setLoading(false)
    }
  }, [zoom_agreement_id])

  const handleCreateAgreement = useCallback(async () => {
    setLoading(true)
    const signature = await getDigitalSignature()

    try {
      const result = await ZoomHelpers.createZoomAgreementAsync(signer_user_id, signature)
      const otherParty = result.data.signer_full_name || 'other party'

      alert(
        `You have successfully signed a digital user agreement. Now it is waiting to be signed by ${otherParty} to access Zoom meetings.`,
      )
      onClose()
    } catch (err) {
      console.log(err.message)
      alert(err.message || Settings.unknownErrorMessage)
    } finally {
      setLoading(false)
    }
  }, [signer_user_id])

  return (
    <Wrapper>
      <ZoomAgreementModalLeftPartition/>

      <ZoomAgreementModalRightPartition
        {...{
          loading,
          selected,
          setSelected,
          fullName,
          onClose,
          submitDisabled,
          isSigning,
          handleSignAgreement,
          handleCreateAgreement,
          submitLabel,
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 620px;
  display: flex;
  border-radius: inherit;
  flex-display: row;
  flex-wrap: nowrap;
  align-items: center;

  @media (max-width: 768px) {
    height: 100dvh;
    overflow-y: auto;
    flex-direction: column;
    border-radius: 0;
  }
`

export default reduxForm({
  form: FORM_NAME,
  initialValues: {
    full_name: Settings.enableInitialSignature ? AuthHelper.currentUser().full_name : '',
  },
})(ZoomMeetingAgreementModal)
