import React from 'react';
import { Field, reduxForm } from 'redux-form';

import Text from 'components/reusable/text';
import Flex from 'components/reusable/flex';
import Indent from 'components/reusable/indent';
import ReduxField from 'components/reusable/input/components/ReduxField';
import SignUpAdditionalStepsProgress from './sign_up_additional_steps_progress';
import { StyledForm } from './styled';
import { signUpStepsData } from '../helpers/signup_steps_helper';
import SignUpAdditionalStepsButtonsToolbar from './sign_up_additional_steps_buttons_toolbar';
import SignUpAdditionalStepsCloseButton from './sign_up_additional_steps_close_button';
import SignUpAdditionalStepsHeading from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_additional_steps_heading';
import Settings from 'helpers/settings';
import ffHelpers from 'helpers/ff_helpers';
import Error from 'components/reusable/error';
import { WrapperWithProgressIndent } from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';

const validate = data => {
  const errors = {};
  if (!data[signUpStepsData.intention.field])
    errors[signUpStepsData.intention.field] = 'Please select your indent role';
  return errors;
};

const SignUpIntentionStep = ({
  name,
  index,
  progress,
  handleSubmit,
  serverError,
  onClose,
  onBack,
  ...props
}) => {
  const isSubmitDisabled = props.invalid || props.submitting || props.pristine;

  return (
    <StyledForm onSubmit={handleSubmit}>
      <SignUpAdditionalStepsProgress value={progress} />

      <WrapperWithProgressIndent>
        {Settings.signUpAdditionalStepsSkippable ? (
          <SignUpAdditionalStepsCloseButton onClick={onClose} />
        ) : null}

        <Indent top={24} />
        <SignUpAdditionalStepsHeading label="What do you want to do?" index={index} required />
        <Indent top={24} />

        <Flex jc="space-between" gap={16}>
          <Flex>
            <Field
              width={30}
              name={name}
              valida={ffHelpers.required}
              type="radio"
              value="client"
              component={ReduxField}
            />
            <Indent right={8} />
            <Text size={16}>I want to hire talent</Text>
          </Flex>

          <Flex>
            <Field
              width={30}
              name={name}
              type="radio"
              value="talent"
              component={ReduxField}
            />
            <Indent right={8} />
            <Text size={16}>I am here to find work</Text>
          </Flex>
        </Flex>

        <Indent top={8} />
        <Error textAlign={'center'}>{serverError}</Error>
        <Indent top={36} />
        <SignUpAdditionalStepsButtonsToolbar onBack={onBack} isSubmitDisabled={isSubmitDisabled} />
      </WrapperWithProgressIndent>
    </StyledForm>
  );
};

export default reduxForm({
  form: signUpStepsData.intention.form,
  destroyOnUnmount: false,
  validate
})(SignUpIntentionStep);
