import React from 'react';

import { colors } from 'helpers/colors';

function SvgCheckboxV3SelectedSolid({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.653961 3.27606C0 4.55953 0 6.23969 0 9.6V12.4C0 15.7603 0 17.4405 0.653961 18.7239C1.2292 19.8529 2.14708 20.7708 3.27606 21.346C4.55953 22 6.23969 22 9.6 22H12.4C15.7603 22 17.4405 22 18.7239 21.346C19.8529 20.7708 20.7708 19.8529 21.346 18.7239C22 17.4405 22 15.7603 22 12.4V9.6C22 6.23969 22 4.55953 21.346 3.27606C20.7708 2.14708 19.8529 1.2292 18.7239 0.653961C17.4405 0 15.7603 0 12.4 0H9.6C6.23969 0 4.55953 0 3.27606 0.653961C2.14708 1.2292 1.2292 2.14708 0.653961 3.27606ZM15.7357 8.94975C16.1263 8.55922 16.1263 7.92606 15.7357 7.53553C15.3452 7.14501 14.712 7.14501 14.3215 7.53553L9.37177 12.4853L7.25045 10.364C6.85992 9.97344 6.22676 9.97344 5.83623 10.364C5.44571 10.7545 5.44571 11.3877 5.83623 11.7782L9.37177 15.3137L15.7357 8.94975Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgCheckboxV3SelectedSolid;
