import { useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AuthHelper from 'helpers/auth_helper';
import { finishSignUp } from 'helpers/api/user';
import UserAPI from 'api/entities/user';
import { RootContext } from 'contexts/contexts';
import Settings from 'helpers/settings';
import { signUpStepsData } from 'components/modals/v2/auth/sign_up_additional_details_modal/helpers/signup_steps_helper';
import SC_GA4, { actions } from 'classes/sc_ga4';
import { LocalStorage } from 'classes/sc_localstorage';
import Globals from 'helpers/globals'

const useSubmitStep = ({
  index,
  setStep,
  signUpSteps,
  isLastStep,
  navigateToLogoStep,
  setError
}) => {
  const formValues = useSelector(signUpFormsSelector);
  const rootContext = useContext(RootContext);
  const dispatch = useDispatch();
  const userAPI = new UserAPI(dispatch);

  const onSubmitStep = useCallback(
    async data => {
      const user = normalizeUserAttrs(formValues);
      setError(null);

      try {
        if (
          data.instagram &&
          AuthHelper.currentUser()?.instagram !== data.instagram
        ) {
          const isTaken = await userAPI.checkIfInstagramTaken(data.instagram);
          if (isTaken) throw 'Instagram is already taken';
        }

        const currentStep = signUpSteps[index];
        _sendEvent(currentStep, formValues);

        if (isLastStep) {
          const result = await finishSignUp(user);
          if (result.error) throw result.message;
          await AuthHelper.loadFromBackend();
          rootContext.setLoading(false);
          navigateToLogoStep();
          _sendAdditionDetailsEvent(formValues);
        } else {
          setStep(prev => prev + 1);
        }
      } catch (err) {
        console.log(err);
        rootContext.setLoading(false);
        const msg =
          typeof err === 'string' ? err : Settings.unknownErrorMessage;
        setError(msg);
      }
    },
    [isLastStep, setStep, index, formValues, signUpSteps]
  );

  return onSubmitStep;
};

function normalizeUserAttrs(data) {
  const { phone, location, ...rest } = data;

  const user = {
    ...(location && { ...location, gmaps_place_id: location.placeID }),
    ...(phone && {
      phone_country_code: phone.code,
      phone_number: phone.phone
    }),
    ...rest
  };

  if (data.user_type === Globals.categories.female_models.singularTitle) {
    user.profiles = { Model: { gender: 'Female' } };
  } else if (data.user_type === Globals.categories.male_models.singularTitle) {
    user.profiles = { Model: { gender: 'Male' } };
  } else if (data.user_type) {
    user.profiles = { [data.user_type]: null };
  }

  Object.keys(user).forEach(k => {
    if (user[k] === '') delete user[k];
  });

  return user;
}

function _sendAdditionDetailsEvent(formValues) {
  SC_GA4.sendMetric(
    actions.userCreateFunnel.additionalInfo,
    _getGa4Attrs(formValues)
  );
}

function signUpFormsSelector(state) {
  const allowedForms = Object.values(signUpStepsData).map(d => d.form);
  const formState = state.form;
  const values = {};

  Object.keys(formState).forEach(k => {
    if (allowedForms.includes(k)) {
      const formValues = formState[k].values || {};
      Object.keys(formValues).forEach(k => (values[k] = formValues[k]));
    }
  });
  return values;
}

function _sendEvent(step, formValues) {
  const { event } = step;
  if (!event) return console.error('Step missing');
  SC_GA4.sendMetric(event, _getGa4Attrs(formValues));
}

function _getGa4Attrs(formValues) {
  let oauth;
  const user = AuthHelper.currentUser();
  // const oauth = formValues[signUpStepsData.oauth.field];
  const profile_type = formValues[signUpStepsData.profile.field];
  const intent_role = formValues[signUpStepsData.intention.field];
  const social = formValues[signUpStepsData.ig.field];
  const referral = formValues[signUpStepsData.referral.field];

  if (user.google_id) oauth = 'google';
  const usedReferralLink = (LocalStorage.fetchReferralCode() || '') !== '';
  const referralProvided = typeof referral === 'string' && referral.length > 0;

  return {
    signup_divided_steps_flow: true,
    ...(oauth && { oauth }),
    ...(profile_type && { profile_type }),
    ...(intent_role && { intent_role }),
    ...(typeof social === 'string' && { social_filled: social.length > 0 }),
    ...((typeof referral === 'string' || usedReferralLink) && {
      referral_filled: usedReferralLink || referralProvided
    })
  };
}

export default useSubmitStep;
