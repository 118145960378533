import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded'

import { getV2, postV2 } from 'api'
import ChatHelpers from 'helpers/chat_helpers'

const client = ZoomMtgEmbedded.createClient()

class ZoomHelpers {

  static signAgreementAsync(zoomAgreementID, signature) {
    const formData = new FormData()
    if (signature) formData.append('signer_signature', signature)
    return postV2(`/v2/zoom/agreements/${zoomAgreementID}/signatures`, { formData })
  }

  static createZoomAgreementAsync(signer_user_id, signature) {
    const formData = new FormData()
    if (signer_user_id) formData.append('signer_user_id', signer_user_id)
    if (signature) formData.append('initiator_signature', signature)
    return postV2(`/v2/zoom/agreements`, { formData })
  }

  static inviteAssistantToMeeting(zoomMeetingGUID, invitee_user_id) {
    const body = { invitee_user_id }
    return postV2(`/v2/zoom/meetings/${zoomMeetingGUID}/invitations`, { body })
  }

  static getZoomAgreementAsync(agreement_id) {
    return getV2(`/v2/zoom/agreements/${agreement_id}}`)
  }

  static getZoomAgreementsBySignerAsync(signer_user_id) {
    return getV2(`/v2/zoom/agreements?signer_user_id=${signer_user_id}`)
  }

  static getSignaturesAsync(zoomMeetingGUID) {
    return getV2(`/v2/zoom/meetings/${zoomMeetingGUID}/signatures`)
  }

  static async createEmbeddedMeeting({
    meetingNumber,
    signature,
    passWord,
    sdkKey,
    zak,
    zoomAppRoot, // dom element
    userEmail = '',
    userName = '',
    tk = '',
    debug = false,
  }) {
    const viewWidth = 500
    await client.init({
      leaveOnPageUnload: false,
      zoomAppRoot,
      language: 'en-US',
      debug,
      customize: {
        video: {
          // isResizable: false, // adding ability to resize causes some bugs
          // popper: { disableDraggable: false },
          // viewSizes: {
          //   default: {
          //     width: viewWidth,
          //     height: (viewWidth / 16) * 9
          //   },
          //   ribbon: {
          //     width: 300,
          //     height: 700
          //   }
          // }
        },
      },
    })

    await client.join({
      signature,
      sdkKey,
      meetingNumber,
      password: passWord,
      userName,
      userEmail,
      zak,
      tk,
      error: error => {
        console.log(error)
      },
      success: joinResult => {
        console.log('ZoomMtg.join', joinResult)
      },
    })

    return client
  }

  static async createFullScreenMeeting({
    meetingNumber,
    signature,
    passWord,
    leaveUrl,
    sdkKey,
    userEmail = '',
    userName = '',
    tk = '',
    debug = false,
  }) {
    // const { ZoomMtg } = await import("@zoomus/websdk")
    // ZoomMtg.setZoomJSLib('https://source.zoom.us/3.8.0/lib', '/av');

    const { ZoomMtg } = await import('@zoom/meetingsdk')
    ZoomMtg.preLoadWasm()
    ZoomMtg.prepareWebSDK()

    ZoomMtg.init({
      debug,
      leaveUrl,
      success: () => {
        ZoomMtg.join({
          signature,
          sdkKey,
          meetingNumber,
          passWord,
          userName,
          userEmail,
          tk,
          error: error => {
            console.log(error)
          },
          success: joinResult => {
            console.log('ZoomMtg.join', joinResult)
          },
        })
      },
      error: error => {
        console.log(error)
      },
    })
  }
}

export default ZoomHelpers
