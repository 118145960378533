import React, { memo, useCallback, useEffect, useState } from 'react';

import Indent from 'components/reusable/indent';
import Text from 'components/reusable/text';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import usePlacementDefinition from 'components/complex/tip/hooks/use_placement_definition';
import { Wrapper, MessageCorner, TipWrapper } from './styled';

const Tip = (
  {
    label,
    desc,
    children,
    className,
    xAxis = 'center',
    yAxis = 'bottom',
    trigger = svgIcons.info(colors.primary[500], { width: 16 })
  },
  wrapperRef
) => {
  const [tipShown, setTipShown] = useState(false);
  const onCloseTip = useCallback(() => setTipShown(false), [setTipShown]);
  const onShowTip = useCallback(() => setTipShown(true), [setTipShown]);
  const { xOffset, definePlacement } = usePlacementDefinition(wrapperRef);

  if (!desc && !children) return null;

  return (
    <Wrapper onMouseOver={onShowTip} onMouseLeave={onCloseTip} className={className}>
      {trigger}

      {tipShown && (
        <TipWrapper $yAxis={yAxis} $xAxis={xAxis} ref={definePlacement} xOffset={xOffset}>
          {children || (
            <>
              <Text size={12} weight={700}>
                {label}
              </Text>
              <Indent bottom={4} />
              <Text size={12} weight={400}>
                {desc}
              </Text>
            </>
          )}
        </TipWrapper>
      )}

      {tipShown && <MessageCorner $yAxis={yAxis} />}
    </Wrapper>
  );
};

export default React.forwardRef(Tip);
