import FileHelpers from 'helpers/file_helpers';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import capitalize from 'lodash.capitalize';
import html2canvas from 'html2canvas';

import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import { noSelect } from 'helpers/style';

function Signature({ value }) {
  const handledValue = useMemo(() => {
    return value
      .split(' ')
      .map(capitalize)
      .join(' ');
  }, [value]);

  return (
    <SignatureWrapper id="digital-signature">
      <StyledText size={getFontSize(handledValue)} lines={1}>
        {handledValue}
      </StyledText>
    </SignatureWrapper>
  );
}

// potentially very heavy operation. we SHOULD NOT consider adding this as onChange callback
export const getDigitalSignature = async () => {
  const element = document.getElementById('digital-signature');
  if (!element) return null;

  const canvas = await html2canvas(
    element,
    { scale: 2 } // removes box shadow bug which results in gray squares on the picture
  );
  return FileHelpers.canvasToFile(canvas, 'digital-signature.png');
};

const getFontSize = (text = '') => {
  const element = document.getElementById('digital-signature');
  let multiplier = 1,
    size = 64;
  if (element) {
    const DEFAULT_WIDTH = 480;
    const { width } = element.getBoundingClientRect();
    multiplier = width / DEFAULT_WIDTH;
  }

  if (text.length > 16) size = 54;
  if (text.length > 22) size = 48;
  if (text.length > 26) size = 42;
  if (text.length > 32) size = 36;

  return Math.ceil(multiplier * size);
};

const SignatureWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: ${colors.light};
  height: 96px;
  min-height: 96px;
  width: 100%;
  box-shadow: 0px 0px 4px rgba(31, 30, 30, 0.24);
  cursor: not-allowed;
`;

const StyledText = styled(Text)`
  word-break: break-all;
  font-family: Allison;
  text-align: center;
  line-height: unset;
  transform: unset;
  ${noSelect};
`;

export default Signature;
