import React, { useMemo } from 'react';
import styled from 'styled-components';

import SvgCheckboxV3SelectedSolid from 'components/reusable/svg/svg_checkbox_v3_selected_solid';
import SvgCheckboxV3UnselectedOutline from 'components/reusable/svg/svg_checkbox_v3_unselected_outline';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';

const Wrapper = styled.span`
  cursor: pointer;
  ${props => props.css}
`;

const Checkbox = ({
  selectedColor = colors.primary[500],
  unselectedColor = colors.gray[200],
  value,
  onChange,
  version = 1,
  css,
  width
}) => {
  const truthyIcon = useMemo(() => {
    if (version === 1) return svgIcons.checkboxTruthyIconV1(colors.primary[500], { width }); // red circle
    if (version === 2)
      // deprecated
      return svgIcons.checkboxTruthyIconV2(colors.gray[300], { width }); // red square

    if (version === 3) return <SvgCheckboxV3SelectedSolid color={selectedColor} size={width} />;
  }, [version]);

  const falsyIcon = useMemo(() => {
    if (version === 1) return svgIcons.checkboxFalsyIconV1(colors.gray[300], { width }); // blank circle
    if (version === 2)
      // deprecated
      return svgIcons.checkboxFalsyIconV2(colors.gray[300], { width }); // blank square

    if (version === 3) return <SvgCheckboxV3UnselectedOutline color={unselectedColor} size={width} />;
  }, [version]);

  const onToggle = () => onChange && onChange(!value);
  return (
    <Wrapper onClick={onToggle} css={css}>
      {value ? truthyIcon : falsyIcon}
    </Wrapper>
  );
};

export default Checkbox;
