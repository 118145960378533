const $ = window.jQuery;

const NotificationHelpers = {
  LSKey: 'unread_notifications',

  // not used
  // loadNotifications: (offset, limit, callback) => {
  //   $.ajax({
  //     url: '/notification',
  //     method: 'GET',
  //     data: {
  //       limit,
  //       offset
  //     },
  //     dataType: 'JSON',
  //     error: (jqXHR, textStatus, errorThrown) => {
  //       console.log(`AJAX Error: ${errorThrown}`);
  //     },
  //     success: (data, textStatus, jqXHR) => {
  //       callback(data.notifications);
  //     }
  //   });
  // },

  viewNewNotifications: callback => {
    $.ajax({
      method: 'POST',
      url: '/notification/viewed_new_notifications',
      dataType: 'JSON',
      data: {},
      success: callback,
      error: error => {
        console.log(error);
      }
    });
  },
  viewNewConversations: callback => {
    $.ajax({
      method: 'POST',
      url: '/conversation/viewed_new_conversations',
      dataType: 'JSON',
      data: {},
      success: requestData => {
        // console.log(requestData)
        callback(requestData);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  // not used
  // getUnviewedConversationsCount: callback => {
  //   $.ajax({
  //     method: 'GET',
  //     url: '/unviewed_conversations_count',
  //     dataType: 'JSON',
  //     data: {},
  //     success: requestData => {
  //       callback(requestData.unviewed_conversations_count);
  //     },
  //     error: error => {
  //       console.log(error);
  //     }
  //   });
  // },

  // TODO add tie this func to header unread messages count badge
   getUnreadConversationsCount: callback => {
     $.ajax({
       method: 'GET',
       url: '/unread_conversations_count',
       dataType: 'JSON',
       data: {},
       success: requestData => {
         callback(requestData.unread_conversations_count);
       },
       error: error => {
         console.log(error);
       }
     });
   },

  // not used
  // getUnviewedNotificationsCount: callback => {
  //   $.ajax({
  //     method: 'GET',
  //     url: '/unviewed_notifications_count',
  //     dataType: 'JSON',
  //     data: {},
  //     success: requestData => {
  //       callback(requestData.unviewed_notifications_count);
  //     },
  //     error: error => {
  //       console.log(error);
  //     }
  //   });
  // },

  readNotification: (notification_id, callback) => {
    $.ajax({
      method: 'POST',
      url: `/notification/${notification_id}/read_notification`,
      dataType: 'JSON',
      data: {},
      success: requestData => {
        callback(requestData);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  notificationText(notification) {
    var notification_data = notification.data;
    var notification_type = notification.notification_type;

    let text_dictionary = {
      agreementSigned: [
        {
          text: `The invitation has been successfully signed by ${notification_data.signer_full_name}! You can now start your Zoom meeting.`,
          font: 'light'
        }
      ],
      agreementInvited: [
        { text: notification_data.initiator_full_name, font: 'heavy' },
        { text: ` has invited you to sign Zoom invitation to access Zoom meetings. Click here to sign.`, font: 'light' }
      ],
      agreementInitiated: [
        { text: `You have successfully signed Zoom invitation. Now it is waiting to be signed by `, font: 'light' },
        { text: notification_data.signer_full_name, font: 'heavy' },
        { text: ' to access Zoom meetings.', font: 'light' }
      ],
      clientSelectTalent: [
        {
          text: 'You have been selected for a job! Click here to accept or decline.',
          font: 'light'
        }
      ],
      offWaitlist: [
        {
          text: 'You have been let off the waitlist! Welcome to Swipecast!',
          font: 'light'
        }
      ],
      proposeConnection: [
        { text: notification_data.requestor_full_name, font: 'heavy' },
        { text: ' invited you to connect.', font: 'light' }
      ],
      acceptConnection: [
        { text: notification_data.requestee_full_name, font: 'heavy' },
        { text: ' has accepted your request to connect.', font: 'light' }
      ],
      jobApproved: [{ text: 'Your job has been approved!', font: 'light' }],
      projectApproved: [{ text: 'Your project has been approved!', font: 'light' }],
      newJobNearby: [{ text: 'There is a new job in your area!', font: 'light' }],
      inviteToJob: [
        { text: notification_data.inviter_full_name, font: 'heavy' },
        { text: ' has invited you to apply to a job!', font: 'light' }
      ],
      clientDeselectTalent: [
        { text: notification_data.client_full_name, font: 'heavy' },
        { text: ' has deselected you for your job.', font: 'light' }
      ],
      clientCancelJob: [
        { text: notification_data.client_full_name, font: 'heavy' },
        { text: ' cancelled your job.', font: 'light' }
      ],
      clientDeselectJob: [
        { text: notification_data.client_full_name, font: 'heavy' },
        { text: ' de-selected you from the project.', font: 'light' }
      ],
      cardChargeFailed: [{ text: 'Your card payment failed.', font: 'light' }],
      expiredJob: [
        { text: 'Your project ', font: 'light' },
        { text: notification_data.project_name, font: 'heavy' },
        { text: ' has expired.', font: 'light' }
      ],
      completedJob: [{ text: 'Congratulations! You completed your job.', font: 'light' }],
      verificationNeeded: [
        {
          text: 'In order to be able to pay you out, we need to add a few details to your account.',
          font: 'light'
        }
      ],
      moneyTransfered: [{ text: 'We just deposited funds in your bank account.', font: 'light' }],
      talentAccept: [
        { text: notification_data.talent_full_name, font: 'heavy' },
        { text: ' accepted your job request.', font: 'light' }
      ],
      talentDecline: [
        { text: notification_data.talent_full_name, font: 'heavy' },
        { text: ' declined your job request.', font: 'light' }
      ],
      talentWithdraw: [
        { text: notification_data.talent_full_name, font: 'heavy' },
        { text: ' has withdrawn from your project.', font: 'light' }
      ],
      thirdPartyPaymentEntered: [
        {
          text: 'The third party payer you selected has submitted payment information for your project.',
          font: 'light'
        }
      ],
      confirmedJob: [
        { text: 'Congratulations! Your project ', font: 'light' },
        { text: notification_data.project_name, font: 'heavy' },
        { text: ' has been confirmed.', font: 'light' }
      ],
      inviteToCasting: [
        {
          text: 'You have been invited to participate in the casting for ',
          font: 'light'
        },
        { text: notification_data.project_name, font: 'heavy' }
      ],
      confirmedCasting: [
        {
          text: ' You have accepted to participate in the casting for ' + notification_data.project_name,
          font: 'light'
        }
      ],
      profileViewed: [
        { text: 'Congratulations! You had ', font: 'light' },
        { text: notification_data.visits_count, font: 'heavy' },
        {
          text: ` new visit${notification_data.visits_count === 1 ? '' : 's'} to your profile.`,
          font: 'light'
        }
      ]
    };

    if (notification_type === 'talentApplyToJob') {
      if (notification_data.other_applicants_count === 0) {
        return [
          { text: notification_data.talent_full_name, font: 'heavy' },
          { text: ' has applied to ', font: 'light' },
          { text: notification_data.project_name, font: 'heavy' },
          { text: '.', font: 'light' }
        ];
      } else if (notification_data.other_applicants_count === 1) {
        return [
          { text: notification_data.talent_full_name, font: 'heavy' },
          { text: ' and ', font: 'light' },
          { text: notification_data.other_applicants_count, font: 'heavy' },
          { text: ' other have applied to ', font: 'light' },
          { text: notification_data.project_name, font: 'heavy' },
          { text: '.', font: 'light' }
        ];
      }

      return [
        { text: notification_data.talent_full_name, font: 'heavy' },
        { text: ' and ', font: 'light' },
        { text: notification_data.other_applicants_count, font: 'heavy' },
        { text: ' others have applied to ', font: 'light' },
        { text: notification_data.project_name, font: 'heavy' },
        { text: '.', font: 'light' }
      ];
    }

    return text_dictionary[notification_type];
  }
};

export default NotificationHelpers;
