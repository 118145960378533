import React, {
  useState,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import throttle from 'lodash.throttle';

import { getNotifications, getUnreadNotificationsCount } from 'store/reducers';
import {
  fetchNotifications,
  fetchUnreadNotificationsCount,
  fetchUnreadNotificationsCountSuccess
} from 'store/actions';
import sc_ga4, { actions } from 'classes/sc_ga4';
import ApplicationHelper from 'helpers/application_helper';
import AuthHelper from 'helpers/auth_helper';
import NotificationHelpers from 'helpers/notification_helpers';
import LoggedInHeaderItems from './components/header_right_items/logged_in_header_right_items';
import LoggedOutHeaderItems from './components/header_right_items/logged_out_header_right_items';
import { OpacityWrap, Container } from './styled';
import useClickOutside from './hooks/useClickOutside';
import HeaderHamburger from './components/hamburger/hamburger';
import HeaderLeftItems from './components/header_left_items/header_left_items';

const $ = window.jQuery;

const DefaultHeaderContainer = ({
  fetchNotifications,
  fetchUnreadNotificationsCount,
  fetchUnreadNotificationsCountSuccess,
  notifications,
  rootProps
}) => {
  const history = useHistory();

  const [isModalVisible, setModalVisible] = useState({
    notifications: false,
    login: false
  });

  const [isMenuOpen, setMenuOpen] = useState({
    logged_in: false,
    logged_out: false
  });

  const [searchResult, setSearchResult] = useState([]);
  const [searchActive, setSearchActive] = useState(false);

  const cancelSearch = () => {
    setSearchResult([]);
    setSearchActive(false);
    document.getElementById('search-input').value = '';
  };

  const closeHamburger = () => {
    setMenuOpen({ logged_in: false, logged_out: false });
  };
  const handleHamburger = () => {
    setMenuOpen({ logged_in: false, logged_out: !isMenuOpen.logged_out });
  };

  useEffect(() => {
    closeHamburger();
  }, [history.location.pathname]);

  useEffect(() => {
    if (AuthHelper.logged_in) {
      fetchNotifications();
      fetchUnreadNotificationsCount();
    }
  }, []);

  const pressTab = tab => {
    switch (tab) {
      case 'create_project':
        sc_ga4.sendMetric(actions.projectCreateFunnel.init, {
          is_booking: false,
          section: 'Header'
        });
        history.push('/create_project');
        break;
      case 'notifications':
        setModalVisible({ notifications: true, login: false });
        notifications.data.length > 0 &&
          NotificationHelpers.viewNewNotifications(() => {
            fetchUnreadNotificationsCountSuccess(0);
          });
        break;
      case 'hamburger':
        setMenuOpen({ logged_in: true, logged_out: false });
        break;
      case 'faq':
        closeHamburger();
        break;
      case 'contact':
        closeHamburger();
        break;
      default:
        return;
    }
  };

  const searchRequest = value => {
    $.ajax({
      url: '/query',
      method: 'POST',
      data: {
        query: {
          search: value,
          offset: 0,
          limit: 5,
          type: 'user'
        }
      },
      dataType: 'JSON',
      error(jqXHR, textStatus, errorThrown) {
        $('body').append(`AJAX Error: ${errorThrown}`);
      },
      success: (data, textStatus) => {
        if (textStatus === 'success') {
          setSearchResult(data.result);
          setSearchActive(true);
        }
      }
    });
  };

  const handleSearchChange = throttle(e => searchRequest(e.target.value), 500);

  const isSearchVisible =
    AuthHelper.logged_in && !AuthHelper.logged_in_waitlisted;
  const ref = useClickOutside(closeHamburger);

  const renderLeftItems = () => {
    const leftItemsProps = {
      closeHamburger,
      isSearchVisible,
      handleSearchChange,
      searchResult,
      cancelSearch,
      searchActive,
      isModalVisible,
      setModalVisible
    };

    return <HeaderLeftItems {...leftItemsProps} />;
  };

  const renderRightItems = () => {
    const loggedInItemsProps = {
      pressTab,
      rootProps,
      notifications,
      closeHamburger,
      isModalVisible,
      setModalVisible,
      isMenuOpen: isMenuOpen.logged_in
    };

    const loggedOutItemsProps = {
      pressTab,
      onOpen: handleHamburger,
      isMenuOpen: isMenuOpen.logged_out
    };

    if (AuthHelper.logged_in)
      return <LoggedInHeaderItems {...loggedInItemsProps} />;
    return <LoggedOutHeaderItems {...loggedOutItemsProps} />;
  };

  const isLoggedOutMobile = ApplicationHelper.isMobile && !AuthHelper.logged_in;

  return (
    <>
      {isLoggedOutMobile ? (
        <div ref={ref}>
          <Container
            jc="space-between"
            wrap="nowrap"
            burgerOpen={isMenuOpen.logged_out}
          >
            {renderLeftItems()}
            {renderRightItems()}
          </Container>

          <HeaderHamburger
            active={isMenuOpen.logged_out}
            pressTab={pressTab}
            close={closeHamburger}
          />
        </div>
      ) : (
        <>
          <Container
            jc="space-between"
            wrap="nowrap"
            burgerOpen={isMenuOpen.logged_out}
            ref={ref}
          >
            {renderLeftItems()}
            {renderRightItems()}
          </Container>

          <HeaderHamburger
            active={isMenuOpen.logged_out}
            pressTab={pressTab}
            close={closeHamburger}
          />
        </>
      )}

      <OpacityWrap active={isMenuOpen.logged_out} />
    </>
  );
};

const mapStateToProps = state => ({
  notifications: getNotifications(state),
});

const DefaultHeader = connect(mapStateToProps, {
  fetchNotifications,
  fetchUnreadNotificationsCount,
  fetchUnreadNotificationsCountSuccess
})(DefaultHeaderContainer);

export default DefaultHeader;
