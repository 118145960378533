/* eslint-disable max-len */
// prettier-ignore
import { lazy } from 'react';

import { default as DeadEndView } from 'components/static/dead_end';

// if a user got redirected from a page with lazy, therefore with spinner, to DeadEnd and if DeadEnd is wrapped in lazy
// as well, I believe this would cause multiple loaders rendered
export const DeadEnd = DeadEndView;

// lazy
export const CreateJobView = lazy(() =>
  import('components/views/logged_in/jobs/create_job/create_job_v2/public_posting/create_job_view')
);
export const DiscoverView = lazy(() => import('components/views/logged_in/discover/discover_view'));
export const SearchView = lazy(() => import('components/views/logged_in/network/search_view'));
export const EmailReadyIndividualBoardView = lazy(() =>
  import('components/views/logged_in/network/boards/email_ready_individual_board_view')
);
export const CreateJobSuccessView = lazy(() =>
  import('components/views/logged_in/jobs/create_job/create_job_v2/create_job_success')
);
export const DownloadApp = lazy(() => import('components/static/download_app'));
export const ResetPassword = lazy(() => import('components/views/logged_out/reset_password/reset_password'));
export const PrivacyPolicy = lazy(() => import('components/static/privacy_policy'));
export const Contact = lazy(() => import('components/views/logged_in/menu/contact/contact'));
export const SignUpPage = lazy(() => import('components/views/logged_out/SignUp/SignUpPage'));
export const LoginPage = lazy(() => import('components/views/logged_out/login/login_page'));
export const TermsOfService = lazy(() => import('components/static/terms_of_service'));
export const IndividualProjectViewV3 = lazy(() =>
  import('components/views/logged_in/jobs/individual_project/individual_project_v3/individual_project_view')
);
export const EditProjectView = lazy(() => import('components/views/logged_in/jobs/edit_project/edit_project_view'));
export const EditProjectTitleView = lazy(() =>
  import('components/views/logged_in/jobs/edit_project/edit_project_title_view')
);
export const EditProjectPictureView = lazy(() =>
  import('components/views/logged_in/jobs/edit_project/edit_project_picture_view')
);
export const HamburgerMenu = lazy(() => import('components/reusable/legacy/header/hamburger/hamburger_menu'));
export const CreateJobRoleViewV2 = lazy(() =>
  import('components/views/logged_in/jobs/create_job_role_v2/create_job_role_view')
);
export const MyJobsViewV2 = lazy(() =>
  import('components/views/logged_in/jobs/jobs_list/jobs_list_v2/my_jobs_view_v2/my_jobs_view_v2')
);
export const AllJobsViewV2 = lazy(() =>
  import('components/views/logged_in/jobs/jobs_list/jobs_list_v2/all_jobs_view_v2/all_jobs_view_v2')
);
export const IndividualJobViewV2 = lazy(() =>
  import('components/views/logged_in/jobs/individual_job_v2/individual_job_view')
);
export const EditJobView = lazy(() => import('components/views/logged_in/jobs/edit_job/edit_job_view'));
export const EditJobRateView = lazy(() =>
  import('components/views/logged_in/jobs/edit_job/edit_job_rate/edit_job_rate_view')
);
export const EditJobDatesView = lazy(() =>
  import('components/views/logged_in/jobs/edit_job/edit_job_dates/edit_job_dates_view')
);
export const EditJobLocationView = lazy(() =>
  import('components/views/logged_in/jobs/edit_job/edit_job_location/edit_job_location_view')
);
export const EditJobTimeView = lazy(() =>
  import('components/views/logged_in/jobs/edit_job/edit_job_time/edit_job_time_view')
);
export const EditJobNotesView = lazy(() =>
  import('components/views/logged_in/jobs/edit_job/edit_job_notes/edit_job_notes_view')
);
export const DiscoverTalentCategoriesView = lazy(() =>
  import('components/views/logged_in/discover/talent_categories_view')
);
export const SearchInputView = lazy(() => import('components/views/logged_in/network/search_input_view'));
export const ChatView = lazy(() => import('components/views/logged_in/chat/chat_v2/chat_view'));
export const NetworkFilters = lazy(() => import('components/views/logged_in/network/search_filters_view'));
export const IndividualBoardView = lazy(() =>
  import('components/views/logged_in/network/boards/individual_board_view')
);
export const BoardsView = lazy(() => import('components/views/logged_in/network/boards/boards_view'));
export const PaymentSettingsView = lazy(() =>
  import('components/views/logged_in/menu/payment_settings_new/payment_settings_view')
);
export const HelpView = lazy(() => import('components/views/help/help_v3/help_root/help_view_mobile'));
export const WaitlistView = lazy(() => import('components/views/help/help_v1/waitlist_view'));
export const HelpFAQView = lazy(() => import('components/views/help/help_v1/help_faq/faq_view'));
export const Socials = lazy(() => import('components/views/logged_in/menu/my_profile/socials/socials'));
export const SettingsView = lazy(() => import('components/views/logged_in/menu/settings/settings'));
export const ContactsView = lazy(() => import('components/views/logged_in/network/contacts_view'));
export const RequestsView = lazy(() => import('components/views/logged_in/network/requests_view'));
export const MyProfileStatsView = lazy(() => import('components/views/logged_in/menu/my_profile/my_profile_stats'));
export const MyProfileImagesAlbumView = lazy(() =>
  import('components/views/logged_in/menu/my_profile/my_profile_images_album_view')
);
export const MyProfileImagesView = lazy(() =>
  import('components/views/logged_in/menu/my_profile/images_album_view_components/mobile/edit_images_view')
);
export const MyProfileChangePassword = lazy(() =>
  import('components/views/logged_in/menu/my_profile/my_profile_change_password')
);
export const MyProfileBasicInfoView = lazy(() =>
  import('components/views/logged_in/menu/my_profile/basic_info/my_profile_basic_info')
);
export const MyProfileUserView = lazy(() => import('components/views/logged_in/menu/my_profile/my_profile_user_view'));
export const UserProfileView = lazy(() => import('components/views/logged_in/network/user_profile_view'));
export const ThirdPartyPayView = lazy(() =>
  import('components/views/logged_in/jobs/job_third_party_pay/third_party_pay')
);
export const SearchResultsWrapper = lazy(() =>
  import('components/views/logged_in/network/search_results/SearchResultWrapper')
);
export const LandingView = lazy(() => import('components/views/logged_out/landing_page/LandingView'));
export const EditProfile = lazy(() => import('components/views/logged_in/menu/Edit/EditProfile'));
export const ProfileShareV2 = lazy(() =>
  import('components/views/logged_in/network/profile_share/profile_share_v2/profile_share_v2')
);
export const SwipecastProView = lazy(() => import('components/views/logged_out/swipecast_pro/swipecast_pro_view'));
export const GuestLanding = lazy(() => import('components/views/logged_out/guest_landing'));
export const GuestSignUp = lazy(() => import('components/views/logged_out/guest_sign_up'));
export const AddCardView = lazy(() =>
  import('components/views/logged_in/menu/payment_settings_new/add_card/add_card_view')
);
export const BankAccountView = lazy(() =>
  import('components/views/logged_in/menu/payment_settings_new/bank_account/bank_account_view')
);
export const NewHelpSectionView = lazy(() => import('components/views/help/help_v2/SectionView'));
export const LinkInBioShare = lazy(() => import('components/views/logged_in/menu/lib_share/lib_share'));
export const OnboardingView = lazy(() => import('components/views/logged_in/menu/onboarding/onboarding_view'));
export const JobsFiltersView = lazy(() =>
  import('components/views/logged_in/jobs/jobs_list/jobs_list_v2/jobs_filters_view')
);
export const NewBookingOfferView = lazy(() =>
  import('components/views/logged_in/booking_offers/new_booking_offer_view')
);
export const EditBookingOfferView = lazy(() =>
  import('components/views/logged_in/booking_offers/edit_booking_offer_view')
);
export const NewBookingOfferSummaryView = lazy(() =>
  import('components/views/logged_in/booking_offers/new_booking_offer_summary_view/new_booking_offer_summary_view')
);
export const BookingOfferView = lazy(() => import('components/views/logged_in/booking_offers/booking_offer_view'));

// problematic

// TODO deprecate
export const MyProfileFinishSocialSignUp = lazy(() =>
  import('components/views/logged_in/menu/my_profile/finish_social_signup/finish_signup')
);

// NOT used ?
export const ChatMiniConversationLayer = lazy(() =>
  import('components/views/logged_in/chat/chat_mini/chat_mini_conversation_layer')
);

// TODO check if we are going to use this
export const IndividualProjectView = lazy(() =>
  import('components/views/logged_in/jobs/individual_project/individual_project_v1/individual_project_view')
);

// NOT used ?
export const HelpSectionView = lazy(() => import('components/views/help/help_v1/help_section_view'));

// TODO check if we are going to use this
export const IndividualProjectViewNew = lazy(() =>
  import('components/views/logged_in/jobs/individual_project/individual_project_v2/IndividualProjectView')
);

// TODO check if we are going to use this
export const ChatViewOLD = lazy(() => import('components/views/logged_in/chat/chat_v1/chat_view'));

// TODO check if we are going to use this
export const ProfileShareV1 = lazy(() =>
  import('components/views/logged_in/network/profile_share/profile_share_v1/profile_share_v1')
);
