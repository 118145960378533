import { useCallback } from 'react';

import { modalEnum } from 'components/modals/v2/modal';
import { useModalContext } from 'hooks/use_context';

/**
 * @description multiple usages
 */
const useChatZoomAgreementOpen = () => {
  const modalContext = useModalContext();

  /**
   * one of arguments is required
   */
  const openZoomAgreement = useCallback(async (zoom_agreement_id, signer_user_id) => {
    if (zoom_agreement_id) return modalContext.onOpenModal(modalEnum.zoomMeetingAgreement, { zoom_agreement_id });
    if (signer_user_id) return modalContext.onOpenModal(modalEnum.zoomMeetingAgreement, { signer_user_id });
    return false;
  }, []);

  return openZoomAgreement;
};

export default useChatZoomAgreementOpen;
