import Checkbox from 'components/reusable/input/components/Checkbox';
import { colors } from 'helpers/colors';
import ffHelpers from 'helpers/ff_helpers';
import React, { useCallback } from 'react'
import { Field } from 'redux-form';
import styled from 'styled-components';

import Button from 'components/reusable/button';
import Flex from 'components/reusable/flex';
import Indent from 'components/reusable/indent';
import ReduxField from 'components/reusable/input/components/ReduxField';
import Signature from 'components/reusable/signature';
import Text from 'components/reusable/text';
import AuthHelper from 'helpers/auth_helper'

function ZoomAgreementModalRightPartition({
  loading,
  selected,
  setSelected,
  fullName,
  onClose,
  submitDisabled,
  isSigning,
  handleSignAgreement,
  handleCreateAgreement,
  submitLabel
}) {
  const maxLength32 = useCallback(() => ffHelpers.maxLength(32), [])
  const minLength6 = useCallback(() => ffHelpers.minLength(6), [])

  return (
    <Wrapper dir="column" wrap="nowrap" ai="flex-start">
      <Text size={[{ size: 24, maxWidth: 768 }, { size: 32 }]} weight={500} lineHeight="1.1em">
        Zoom Meeting Invitation
      </Text>
      <Indent top={[{ margin: 24, maxWidth: 768 }, { margin: 32 }]} />

      <ContentWrapper>
        <CheckboxWrapper>
          <Indent top={4}>
            <Checkbox selectedColor={colors.zoom} version={3} onChange={setSelected} value={selected} width={20} />
          </Indent>
          <Text size={[{ size: 14, maxWidth: 768 }, { size: 16 }]}>
            By signing this document I agree to keep all communications on Swipecast, including all discussions of
            rates, usage and payments.
          </Text>
        </CheckboxWrapper>
        <Indent top={32} />

        <InputsWrapper>
          <Text size={[{ size: 12, maxWidth: 768 }, { size: 14 }]} textAlign="left">
            Your Full Name
          </Text>
          <Indent top={8} />
          <Field
            width="100%"
            component={ReduxField}
            name="full_name"
            placeholder={AuthHelper.logged_in_fullName}
            autoComplete="off"
            validate={[ffHelpers.required, maxLength32, minLength6]}
          />
          <Indent top={[{ margin: 16, maxWidth: 768 }, { margin: 24 }]} />
          <Text size={[{ size: 12, maxWidth: 768 }, { size: 14 }]} textAlign="left">
            Your Signature
          </Text>
          <Indent top={8} />
          <Signature value={fullName} />
        </InputsWrapper>
      </ContentWrapper>

      <ButtonsWrapper>
        <StyledButton type="gray-shadow" onClick={onClose}>
          No, thanks
        </StyledButton>

        <StyledButton
          loading={loading}
          bgColor={colors.zoom}
          disabled={submitDisabled}
          onClick={isSigning ? handleSignAgreement : handleCreateAgreement}
        >
          {submitLabel}
        </StyledButton>
      </ButtonsWrapper>
    </Wrapper>
  );
}

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: nowrap;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const InputsWrapper = styled.div`
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 160px;
  @media (max-width: 768px) {
    width: 100%;
    height: 40px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  margin-top: 32px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin-top: 24px;
  }
`;

const Wrapper = styled(Flex)`
  max-width: 576px;
  width: 576px;
  padding: 48px;

  @media (max-width: 768px) {
    background-color: ${colors.light};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: -20px;
    padding: 32px 16px 24px;
    width: unset;
  }
`;

export default ZoomAgreementModalRightPartition;
