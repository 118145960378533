import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import { Wrapper } from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';
import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import Flex from 'components/reusable/flex';
import Indent from 'components/reusable/indent';
import SignUpAdditionalStepsCloseButton from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_additional_steps_close_button';
import Button from 'components/reusable/button';
import { StyledField } from 'components/modals/v2/auth/sign_up_additional_details_modal/components/styled';

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ButtonsWrapper = styled(Flex)`
  @media (max-width: 768px) {
    border-top: 1px solid ${colors.gray[50]};
    margin: auto -16px 0;
    padding: 16px 16px 0;
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ResetPasswordForm = ({
  onClose,
  error,
  isSubmitDisabled,
  handleSubmit
}) => {
  return (
    <Wrapper>
      <StyledForm onSubmit={handleSubmit}>
        <SignUpAdditionalStepsCloseButton onClick={onClose} />
        <Indent top={[{ maxWidth: 768, margin: 28 }]} />
        <Text
          textAlign="center"
          size={[{ maxWidth: 768, margin: 20 }, { size: 24 }]}
          weight={500}
          color={colors.gray[700]}
        >
          RESET PASSWORD
        </Text>

        <Indent top={[{ maxWidth: 768, margin: 32 }, { margin: 40 }]} />
        <Field
          component={StyledField}
          name="email"
          placeholder="Email"
          error={error}
        />
        <Indent top={40} />

        <ButtonsWrapper jc="center">
          <StyledButton
            semanticType={'submit'}
            width={160}
            height={40}
            type="primary"
            isDisabled={isSubmitDisabled}
          >
            Submit
          </StyledButton>
        </ButtonsWrapper>
      </StyledForm>
    </Wrapper>
  );
};

export default ResetPasswordForm;