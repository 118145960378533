import queryString from 'query-string';

import ApplicationHelper from 'helpers/application_helper';
import Settings from 'helpers/settings';
import AuthHelper from 'helpers/auth_helper';
import SCPusher from 'classes/sc_pusher';
import SCTrack from 'classes/sc_track';
import SCLocalStorage from 'classes/sc_localstorage';
import { fetchUnreadConversationsCount, fetchUnreadNotificationsCountSuccess } from 'store/actions'
import store from 'store/configureStore'

const $ = window.jQuery;
const jQuery = $;

const LocalStorage = new SCLocalStorage();
const InitHelper = {
  authInit() {
    AuthHelper.loadFromBackend()
      .then(() => {
        if (AuthHelper.logged_in) {
          store.store.dispatch(fetchUnreadConversationsCount());
          SCPusher.bindProfileUpdate()
          SCPusher.bindNotifications()
          SCPusher.bindUnreadConversations()
        }
      })
      .catch(() => {});
  },

  ajaxInit() {
    $.ajaxSetup({
      // Add the correct service token at the beggining and append
      // the auth_token at the end
      beforeSend: (xhr, options) => {
        /**
         * If the URL has a protocol already, we don't do anything.
         * Example use cases: Cloudinary API calls
         */
        if (!options.url.startsWith('http')) {
          const url = Settings.SWIPECAST_BACKEND + options.url;
          xhr.setRequestHeader('Authorization', `Bearer ${AuthHelper.currentAuthToken()}`);
          // eslint-disable-next-line
          options.url = url;
        }
      }
    });
  },

  trackingInit() {
    SCTrack.init();
  },

  resizeInit() {
    jQuery(document).ready($ => {
      $(window).on('resize', () => {
        ApplicationHelper.detectResize();
      });
    });

    ApplicationHelper.detectResize();
  },

  stripeInit() {
    if (window.Stripe) {
      window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    }
  },

  setSearchParamsToStorage() {
    const { search } = window.location;
    const query = queryString.parse(search);
    const { referral_code } = query;
    if (referral_code) LocalStorage.setReferralCode(referral_code);
  }
};

export default InitHelper;
