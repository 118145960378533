import twitter from 'twitter-text';
import RouterHelper from 'helpers/router_helper';

const StringHelpers = {
  findIndicesOfWord(string, regex) {
    const matches = [];
    let match;

    while ((match = regex.exec(string)) !== null) {
      matches.push({
        url: match[0],
        noTargetBlank: true,
        indices: [match.index, match.index + match[0].length]
      });
    }

    return matches;
  },

  // creates <a href="http://www.swipecast.com"> tag from http://www.swipecast.com
  // also it handles Instagram links with @
  autoLink(text, options) {
    const localUrlPattern = /http:\/\/localhost:\d+\/\S*/g;
    const escapedText = twitter.htmlEscape(text);
    const localIndeces = this.findIndicesOfWord(escapedText, localUrlPattern);

    const twitterIndeces = twitter.extractEntitiesWithIndices(text, {
      extractUrlsWithoutProtocol: true
    });

    const entities = localIndeces.concat(twitterIndeces);

    if (entities.length === 0) {
      return text;
    }

    return twitter.autoLinkEntities(twitter.htmlEscape(text), entities, options);
  },

  validateEmail(string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(string);
  },

  validatePassword(string) {
    const re = /(?=.*\d)([\W]|[\w]){8,}/;
    return re.test(string);
  },

  truncate(string, length, ellipsis = true) {
    if (string.length <= length) {
      return string;
    }
    return `${string
      .split('')
      .slice(0, length)
      .join('')}${ellipsis ? '...' : ''}`;
  },

  toTitleCase(str) {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  },

  async copyToClipboard(str) {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(str);
      return true;
    }
    return false;
  }
};

export default StringHelpers;
