import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import Link from 'components/reusable/link'
import AuthHelper from 'helpers/auth_helper'
import { getNotifications } from 'store/reducers'
import { fetchNotifications, fetchUnreadNotificationsCount, fetchUnreadNotificationsCountSuccess } from 'store/actions'
import NotificationHelpers from 'helpers/notification_helpers'
import { getJobsPath, routes } from 'helpers/router_helper'
import Text from 'components/reusable/text'
import { colors } from 'helpers/colors'
import { styleDimensions } from 'helpers/style'
import svgIcons from 'helpers/svgIcons'
import QueryHelpers from 'helpers/query_helpers'
import { useUnreadConversationsCountSelector, useUnreadNotificationsCountSelector } from 'store/sagas/selectors'

const StyledLink = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-evenly;
  cursor: pointer;
  background-color: ${colors.light};
`

const InnerContainer = styled.div`
  position: relative;
`

const TabBarIcon = ({ defaultIcon, selectedIcon, title, isActive, href, to, onClick }) => {
  const color = isActive ? colors.gray[700] : colors.gray[300]

  return (
    <StyledLink className="TabBarIconContainer" to={to} onClick={onClick} href={href}>
      <InnerContainer>{isActive ? selectedIcon : defaultIcon}</InnerContainer>
      <Text size={10} weight={500} color={color}>
        {title.toUpperCase()}
      </Text>
    </StyledLink>
  )
}

const Container = styled.footer`
  z-index: 200;
  height: ${styleDimensions.mobileBarHeight};
  min-height: ${styleDimensions.mobileBarHeight};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  user-select: none;
  flex-direction: row;
  box-shadow: 0px -2px 8px rgba(31, 30, 30, 0.08);
`

const TabBarMobileStatic = ({
  fetchNotifications,
  fetchUnreadNotificationsCount,
  fetchUnreadNotificationsCountSuccess,
}) => {
  const location = useLocation()
  const history = useHistory()
  const unreadNotificationsCount = useUnreadNotificationsCountSelector()?.data || 0
  const unreadConversationsCount = useUnreadConversationsCountSelector()?.data || 0

  useEffect(() => {
    if (AuthHelper.logged_in) {
      fetchNotifications()
      fetchUnreadNotificationsCount()
    }
  }, [])

  const explore = {
    id: 'discover',
    title: 'Discover',
    selectedIcon: svgIcons.search(colors.gray[700]),
    defaultIcon: svgIcons.search(colors.gray[300]),
    to: routes.discover.base.redirectUrl(),
    isActive:
      location.pathname === '/' || /\/discover/g.test(location.pathname) || QueryHelpers.getNavActiveness('discover'),
  }

  const jobs = {
    id: 'jobs',
    title: 'Jobs',
    selectedIcon: svgIcons.suitCase(colors.gray[700]),
    defaultIcon: svgIcons.suitCase(colors.gray[300]),
    isActive:
      [
        routes.newBookingOfferSummary.redirectUrl(),
        routes.newBookingOffer.redirectUrl(),
        routes.jobs.list.redirectUrl(),
        routes.projectShow.redirectUrl(),
        routes.jobs.my.redirectUrl(),
        routes.createProject.redirectUrl(),
      ].includes(location.pathname) ||
      QueryHelpers.getNavActiveness('jobs') ||
      routes.bookingOfferShow.regexp.test(location.pathname),
    to: getJobsPath(),
  }

  const messages = {
    id: 'messages',
    title: 'Messages',
    selectedIcon: unreadConversationsCount
      ? svgIcons.chatWithDot(colors.gray[700])
      : svgIcons.chat(colors.gray[700]),
    defaultIcon: unreadConversationsCount
      ? svgIcons.chatWithDot(colors.gray[300])
      : svgIcons.chat(colors.gray[300]),
    isActive: location.pathname.startsWith(routes.chat.redirectUrl()),
    onClick: () => {
      NotificationHelpers.viewNewConversations(() => {
        history.push(routes.chat.redirectUrl())
      })
    },
  }

  const notifications = {
    id: 'notifications',
    title: 'Alerts',
    selectedIcon: unreadNotificationsCount.data
      ? svgIcons.bellWithDot(colors.gray[700])
      : svgIcons.bell(colors.gray[700]),
    defaultIcon: unreadNotificationsCount.data
      ? svgIcons.bellWithDot(colors.gray[300])
      : svgIcons.bell(colors.gray[300]),
    isActive: location.pathname === routes.notifications.redirectUrl(),
    onClick: () => {
      NotificationHelpers.viewNewNotifications(() => {
        fetchUnreadNotificationsCountSuccess(0)
        history.push(routes.notifications.redirectUrl())
      })
    },
  }

  const profile = {
    id: 'profile',
    title: 'Profile',
    selectedIcon: svgIcons.user(colors.gray[700]),
    defaultIcon: svgIcons.user(colors.gray[300]),
    to: routes.menu.redirectUrl(),
    isActive: location.pathname === routes.menu.redirectUrl(),
  }

  const icons = [explore, jobs, messages, notifications, profile]
  const iconsWaitlisted = [explore, jobs, messages, notifications, profile]

  return (
    <Container>
      {(AuthHelper.logged_in_waitlisted ? iconsWaitlisted : icons).map((item, i) => (
        <TabBarIcon
          key={i}
          isActive={item.isActive}
          defaultIcon={item.defaultIcon}
          selectedIcon={item.selectedIcon}
          title={item.title}
          href={item.href}
          to={item.to}
          onClick={item.onClick}
        />
      ))}
    </Container>
  )
}

const mapStateToProps = state => ({
  notifications: getNotifications(state),
})

const TabBarMobile = connect(mapStateToProps, {
  fetchNotifications,
  fetchUnreadNotificationsCount,
  fetchUnreadNotificationsCountSuccess,
})(TabBarMobileStatic)

export default TabBarMobile
