import useChatZoomAgreementOpen from 'components/views/logged_in/chat/chat_v2/hooks/use_chat_meeting_agreement_open';
import { routes } from 'helpers/router_helper';
import { useRootContext } from 'hooks/use_context';
import React, { useCallback } from 'react';

import NotificationHelpers from 'helpers/notification_helpers';
import ConnectHelpers from 'helpers/connect_helpers';
import NotificationSnapshot from 'components/views/logged_in/notifications/notification_components/notification_snapshot';
import { useHistory } from 'react-router-dom';

const NotificationSnapshotContainer = ({ notification, fetchNotifications }) => {
  const rootProps = useRootContext();
  const handleOpenAgreement = useChatZoomAgreementOpen();
  const history = useHistory();

  const markAsRead = useCallback(() => {
    NotificationHelpers.readNotification(notification.id, fetchNotifications);
  }, [fetchNotifications, fetchNotifications]);

  const handleClick = useCallback(
    (element_clicked = 'body_of_notification') => {
      let notification_data = notification.data;
      let notification_type = notification.notification_type;
      markAsRead(notification.id);

      if (notification_type === 'proposeConnection') {
        if (element_clicked === 'body_of_notification') {
          rootProps.toggleConnectRequestRecipient({
            swipecast_user_id: notification_data.swipecast_user_id
          });
        } else if (element_clicked === 'delete') {
          // this.props.activateLoading();
          ConnectHelpers.declineConnection(
            notification_data.swipecast_user_id,
            notification_data.connection_id,
            fetchNotifications
          );
        } else if (element_clicked === 'accept') {
          ConnectHelpers.acceptConnection(
            notification_data.swipecast_user_id,
            notification_data.connection_id,
            fetchNotifications
          );
        }
      }
      if (notification_type === 'waitingForTalentToAccept') {
        window.location.href = '/my_jobs';
      }
      if (
        [
          'inviteToJob',
          'clientSelectTalent',
          'expiredJob',
          'completedJob',
          'cardChargeFailed',
          'talentApplyToJob',
          'jobApproved',
          'newJobNearby',
          'clientCancelJob',
          'clientDeselectTalent',
          'talentAccept',
          'talentDecline',
          'talentWithdraw',
          'thirdPartyPaymentEntered',
          'confirmedJob'
        ].indexOf(notification_type) !== -1
      ) {
        window.location.href = '/jobs/' + notification_data.swipecast_job_id;
      }
      if (notification_type === 'projectApproved') {
        window.location.href = '/project/' + notification_data.swipecast_project_id;
      }
      if (notification_type === 'acceptConnection') {
        window.location.href = '/profile/' + notification_data.swipecast_user_id;
      }
      if (notification_type === 'inviteToCasting') {
        window.location.href = '/jobs/' + notification_data.swipecast_job_id;
      }
      if (notification_type === 'confirmedCasting') {
        window.location.href = '/jobs/' + notification_data.swipecast_job_id;
      } else if (notification_type === 'agreementInvited') {
        handleOpenAgreement(notification_data.agreement_id);
      } else if (notification_type === 'agreementSigned') {
        history.push(routes.userProfile.redirectUrl(notification_data.signer_user_id));
      }
    },
    [markAsRead, notification, fetchNotifications, handleOpenAgreement]
  );

  return <NotificationSnapshot notification={notification} clickOnElement={handleClick} />;
};

export default NotificationSnapshotContainer;
