import { colors } from 'helpers/colors';
import React from 'react';

function SvgCheckboxV3UnselectedOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 9.6C1 7.90334 1.00078 6.69099 1.07842 5.74064C1.15512 4.80197 1.30179 4.20731 1.54497 3.73005C2.02433 2.78924 2.78924 2.02433 3.73005 1.54497C4.20731 1.30179 4.80197 1.15512 5.74064 1.07842C6.69099 1.00078 7.90334 1 9.6 1H12.4C14.0967 1 15.309 1.00078 16.2594 1.07842C17.198 1.15512 17.7927 1.30179 18.27 1.54497C19.2108 2.02433 19.9757 2.78924 20.455 3.73005C20.6982 4.20731 20.8449 4.80197 20.9216 5.74064C20.9992 6.69099 21 7.90334 21 9.6V12.4C21 14.0967 20.9992 15.309 20.9216 16.2594C20.8449 17.198 20.6982 17.7927 20.455 18.27C19.9757 19.2108 19.2108 19.9757 18.27 20.455C17.7927 20.6982 17.198 20.8449 16.2594 20.9216C15.309 20.9992 14.0967 21 12.4 21H9.6C7.90334 21 6.69099 20.9992 5.74064 20.9216C4.80197 20.8449 4.20731 20.6982 3.73005 20.455C2.78924 19.9757 2.02433 19.2108 1.54497 18.27C1.30179 17.7927 1.15512 17.198 1.07842 16.2594C1.00078 15.309 1 14.0967 1 12.4V9.6Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
}

export default SvgCheckboxV3UnselectedOutline;
