import React, { useCallback, useMemo } from 'react'

import SignUpWorkStepView
  from 'components/modals/v2/auth/sign_up_additional_details_modal/components/sign_up_work_step_view'
import AuthHelper from 'helpers/auth_helper'
import Globals from 'helpers/globals'
import { signUpStepsData } from 'components/modals/v2/auth/sign_up_additional_details_modal/helpers/signup_steps_helper'
import { useProfileFormSelector } from 'store/sagas/selectors'

const SignUpWorkStep = ({
  name,
  index,
  progress,
  onClose,
  onSubmit,
  serverError,
  onBack,
  igError,
  ...props
}) => {
  const profileForm = useProfileFormSelector()
  const user = AuthHelper.currentUser()
  const isSubmitDisabled = props.invalid || props.submitting
  const values = profileForm?.values || {}
  const profileLabel = values[signUpStepsData.profile.field]
  const isExtendedFields = getShouldSeeExtendedFieldsCopy(profileLabel)

  const initialValues = useMemo(() => {
    const values = { [signUpStepsData.ig.field]: user.instagram }

    if (isExtendedFields) {
      values[signUpStepsData.company.field] = user.organization
      values[signUpStepsData.jobTitle.field] = user.job_title
      values[signUpStepsData.website.field] = user.website
    }

    return values
  }, [isExtendedFields])

  return (
    <SignUpWorkStepView
      isExtendedFields={isExtendedFields}
      initialValues={initialValues}
      onSubmit={onSubmit}
      isSubmitDisabled={isSubmitDisabled}
      serverError={serverError}
      progress={progress}
      onClose={onClose}
      igError={igError}
      onBack={onBack}
      index={index}
    />
  )
}

const getShouldSeeExtendedFieldsCopy = (profileLabel) => {
  const profilesToAskJobOrWebsite = [
    Globals.profileTypes.designer,
    Globals.profileTypes.photo,
    Globals.profileTypes.casting,
    Globals.profileTypes.eComManager,
    Globals.profileTypes.creative,
    Globals.profileTypes.producer,
    Globals.profileTypes.studioManager,
    Globals.profileTypes.studio,
    Globals.profileTypes.other,
  ]

  return profilesToAskJobOrWebsite.includes(profileLabel)
}

export default SignUpWorkStep
