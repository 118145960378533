import React, { Suspense } from 'react';
import { withRouter, BrowserRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';

import '../../App.css';
import LoadingScreen from 'components/reusable/legacy/other/loading_screen.js';
import AuthHelper from 'helpers/auth_helper';
import ApplicationHelper from 'helpers/application_helper';
import WebNotificationHelper from 'helpers/web_notification_helpers';
import FaviconHighlighter from '../reusable/legacy/other/favicon_highlighter';
import ModalsView from 'components/views/modals_view';
import { ModalContext, RootContext } from 'contexts/contexts';
import Modal, { modalEnum } from 'components/modals/v2/modal';
import Footer from 'components/complex/footer/footer';
import { searchParams } from 'helpers/router_helper';
import SCTrack from 'classes/sc_track';
import { Wrapper } from './styled';
import Router from './router';
import UaBanner from 'components/static/ua_banner';
import Header from 'components/reusable/header/header';
import withUaBannerProvider from 'hocs/with_ua_banner_provider';
import InitHelper from 'helpers/init_helper';
import UserHelpers from 'helpers/user_helpers';
import LoadingComponent from 'components/reusable/loading/LoadingComponent';
import MobileNavigation from 'components/complex/mobile_navigation';

class RootWrapper extends React.Component {
  state = {
    manualOverflow: null,
    // currentModalContent: modalEnum.signUpLogo,
    currentModalContent: null,
    modalProps: {},
    show_connect_request_recipient: false,
    show_invite_project: false,
    show_invite_friend: false,
    show_job_review: true,
    show_add_board_modal: false,
    add_board_swipecast_user_id: null,
    connect_request_recipient_swipecast_user_id: {},
    connect_request_sender_swipecast_user_id: {},
    book_user_id: null,
    invite_project_user: null,
    show_add_card_modal: false,
    loading_msg: null,
    show_loading: false,
    // show_loading: true,
    showBookingModal: false,
    showShareModal: false,
    showHowItWorks: false,
    showTosModal: false,
    boards_triggered: false,
    mode: queryString.parse(window.location.search).mode,
    messenger_active: localStorage.getItem('messenger_active')
      ? JSON.parse(localStorage.getItem('messenger_active'))
      : true,
    active_mini_chat_window_user_ids: localStorage.getItem('active_mini_chat_window_user_ids')
      ? JSON.parse(localStorage.getItem('active_mini_chat_window_user_ids'))
      : []
  };

  async componentDidMount() {
    InitHelper.resizeInit();
    InitHelper.ajaxInit();
    InitHelper.authInit();
    InitHelper.trackingInit();
    InitHelper.stripeInit();
    InitHelper.setSearchParamsToStorage();

    SCTrack.appCuesPage();
    if ('Notification' in window) WebNotificationHelper.initWebNotifications();
    this.handleCompleteProfileModal();
  }

  setMode = mode => this.setState(() => ({ mode }));

  toggleMessenger = () => {
    const new_messenger_active = !this.state.messenger_active;
    this.setState({ messenger_active: new_messenger_active });
    localStorage.setItem('messenger_active', JSON.stringify(new_messenger_active));
  };

  hideChatWindow = index => {
    const storageProfiles = this.state.active_mini_chat_window_user_ids;
    storageProfiles.splice(index, 1);
    this.setState({ active_mini_chat_window_user_ids: storageProfiles });
    localStorage.setItem('active_mini_chat_window_user_ids', JSON.stringify(storageProfiles));
  };

  toggleAddCardModal = callback => {
    this.setState({
      show_add_card_modal: true,
      add_card_modal_callback: callback
    });
  };

  toggleConnectRequestRecipient = connect_request_details => {
    this.setState({
      show_connect_request_recipient: true,
      connect_request_recipient_swipecast_user_id: connect_request_details.swipecast_user_id
    });
  };

  toggleConnectRequestSender = (connect_request_details, onRequestSuccess = () => {}) => {
    this.setState({
      show_connect_request_sender: true,
      connect_request_sender_swipecast_user_id: connect_request_details.swipecast_user_id,
      on_connect_request_sender_success: onRequestSuccess
    });
  };

  hideConnectRequestRecipient = () => {
    this.setState({ show_connect_request_recipient: false });
  };

  toggleAddBoardModal = swipecast_user_id => {
    this.setState({
      show_add_board_modal: true,
      add_board_swipecast_user_id: swipecast_user_id
    });
  };

  toggleBookingModal = swipecast_user_id => {
    this.setState({
      showBookingModal: true,
      booking_user_id: swipecast_user_id
    });
  };

  toggleShareModal = swipecast_user_id => {
    this.setState({
      showShareModal: true,
      user_id: swipecast_user_id
    });
  };

  toggleInviteToJobPopup = user => {
    this.setState({
      show_invite_project: true,
      invite_project_user: user
    });
  };

  toggleMiniChat = chat_user => {
    const swipecast_user_id = chat_user.swipecast_user_id;
    // current chat window is arbiter of truth
    const storageProfiles = this.state.active_mini_chat_window_user_ids;
    storageProfiles.unshift(swipecast_user_id);
    if (storageProfiles.length > 3) {
      storageProfiles.splice(-1);
    }
    this.setState({
      active_mini_chat_window_user_ids: Array.from(new Set(storageProfiles))
    });
    localStorage.setItem('active_mini_chat_window_user_ids', JSON.stringify(storageProfiles));
  };

  handleCompleteProfileModal = () => {
    const verificationInfoMissing =
      UserHelpers.signupUncompleted(AuthHelper.currentUser()) && !ApplicationHelper.isMobile; // there is another flow on mobile
    const profileUncompleted = AuthHelper.logged_in_profile_types?.length === 0 || verificationInfoMissing;
    const q = queryString.parse(window.location.search);
    const guestOnboarding = q.mode === searchParams.modes.guestOnboarding;

    if (profileUncompleted && !guestOnboarding) {
      this.onOpenModal(modalEnum.signUpAdditionalDetails);
    }
  };

  onCloseConnectRequestSender = () =>
    this.setState({
      show_connect_request_sender: false,
      on_connect_request_sender_success: () => {}
    });

  embedMode = () => this.state.mode === searchParams.modes.embed;
  faviconHighlighter = () => this.state.mode !== searchParams.modes.embed && !ApplicationHelper.isMobile;

  onChangeManualOverflow = manualOverflow => this.setState(() => ({ manualOverflow }));

  componentDidUpdate = () => SCTrack.appCuesPage();
  onCloseConnectRequest = () => this.setState({ show_connect_request_recipient: false });
  onCloseAddCard = () => this.setState({ show_add_card_modal: false });
  onCloseBooking = () => this.setState({ showBookingModal: false });
  onCloseShare = () => this.setState({ showShareModal: false });
  onCloseInviteToJob = () => this.setState({ show_invite_project: false });
  onCloseModal = () => this.setState({ currentModalContent: null, modalProps: {} });
  hideBookingModal = () => this.setState({ showBookingModal: false });
  hideShareModal = () => this.setState({ showShareModal: false });
  enableLoad = () => this.setState({ show_loading: true });
  disableLoad = () => this.setState({ show_loading: false });
  setLoading = (show_loading, loading_msg = null) => {
    if (this.state.show_loading === show_loading && this.state.loading_msg === loading_msg) return null;
    this.setState(() => ({ show_loading, loading_msg: show_loading ? loading_msg : null }));
  };
  onCloseAddBoard = () => this.setState({ show_add_board_modal: false });
  onCloseInviteFriend = () => this.setState({ show_invite_friend: false });
  hideInviteToJobPopup = () => this.setState({ show_invite_project: false });
  inviteAFriend = () => this.setState({ show_invite_friend: true });
  onOpenModal = (modal, modalProps = {}) => this.setState({ currentModalContent: modal, modalProps });

  render() {
    // TODO move to root context
    const rootProps = {
      toggleMiniChat: this.toggleMiniChat,
      pressSidebarChat: this.pressSidebarChat,
      inviteAFriend: this.inviteAFriend,
      toggleBookingModal: this.toggleBookingModal,
      toggleShareModal: this.toggleShareModal,
      hideBookingModal: this.hideBookingModal,
      hideShareModal: this.hideShareModal,
      toggleInviteToJobPopup: this.toggleInviteToJobPopup,
      hideInviteToJobPopup: this.hideInviteToJobPopup,
      toggleConnectRequestRecipient: this.toggleConnectRequestRecipient,
      toggleConnectRequestSender: this.toggleConnectRequestSender,
      hideConnectRequestRecipient: this.hideConnectRequestRecipient,
      toggleAddCardModal: this.toggleAddCardModal,
      toggleAddBoardModal: this.toggleAddBoardModal,
      enableLoad: this.enableLoad,
      disableLoad: this.disableLoad
    };

    const q = queryString.parse(window.location.search);

    const isMobileFooterEnabled =
      ApplicationHelper.isMobile && AuthHelper.logged_in && this.state.mode !== searchParams.modes.guestOnboarding;

    const utm = {
      utm_medium: q.utm_medium,
      utm_source: q.utm_source,
      utm_term: q.utm_term,
      utm_campaign: q.utm_campaign,
      utm_content: q.utm_content
    };

    const modalContextProps = {
      toggleAddBoardModal: this.toggleAddBoardModal,
      onCloseConnectRequest: this.onCloseConnectRequest,
      onCloseConnectRequestSender: this.onCloseConnectRequestSender,
      onCloseAddCard: this.onCloseAddCard,
      onCloseBooking: this.onCloseBooking,
      onCloseShare: this.onCloseShare,
      onCloseInviteToJob: this.onCloseInviteToJob,
      onCloseAddBoard: this.onCloseAddBoard,
      onCloseInviteFriend: this.onCloseInviteFriend,
      onCloseModal: this.onCloseModal,
      onOpenModal: this.onOpenModal,
      onChangeManualOverflow: this.onChangeManualOverflow,
      ...this.state
    };

    const rootContextProps = {
      toggleConnectRequestRecipient: this.toggleConnectRequestRecipient, // currently disabled
      toggleConnectRequestSender: this.toggleConnectRequestSender, // currently disabled
      mode: this.state.mode,
      toggleBookingModal: this.toggleBookingModal,
      toggleInviteToJobPopup: this.toggleInviteToJobPopup,
      setMode: this.setMode,
      setLoading: this.setLoading,
      isLoading: this.state.show_loading,
      isMobileFooterEnabled
    };

    return (
      <ModalContext.Provider value={modalContextProps}>
        <BrowserRouter>
          <RootContext.Provider value={rootContextProps}>
            <Modal
              manualOverflow={this.state.manualOverflow}
              content={this.state.currentModalContent}
              onClose={this.onCloseModal}
              modalProps={this.state.modalProps}
            >
              {this.embedMode() ? null : <Header rootProps={rootProps} />}

              <Wrapper isMobileFooterEnabled={isMobileFooterEnabled} className="root_wrapper">
                {this.props.showUaBaner ? <UaBanner /> : null}
                {this.faviconHighlighter() ? <FaviconHighlighter /> : null}

                <Suspense fallback={<LoadingComponent />}>
                  <Router
                    rootProps={rootProps}
                    utm={utm}
                    // state={this.state}
                    // hideChatWindow={this.hideChatWindow}
                    // toggleMessenger={this.toggleMessenger}
                  />
                </Suspense>

                {isMobileFooterEnabled && <MobileNavigation />}
                <Footer />
                <ModalsView rootProps={rootProps} />
              </Wrapper>
            </Modal>

            <LoadingScreen loading={this.state.show_loading} loading_msg={this.state.loading_msg} />
          </RootContext.Provider>
        </BrowserRouter>
      </ModalContext.Provider>
    );
  }
}

export default withRouter(withUaBannerProvider(connect()(RootWrapper)));
