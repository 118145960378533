import React from 'react';

import NotificationHelpers from 'helpers/notification_helpers';
import DateHelpers from 'helpers/date_helpers';
import OutlinedButton from 'components/reusable/button/outlined_button';
import SolidButton from 'components/reusable/button/solid_button';
import ImageWrapper from 'components/reusable/legacy/other/image_wrapper';

const $ = window.jQuery;
class NotificationSnapshot extends React.Component {
  state = null;
  componentDidMount() {
    const notification_type = this.props.notification.notification_type;
    if (notification_type === 'jobApproved' || notification_type === 'projectApproved') {
      const clientID = JSON.parse(localStorage.getItem('clientId'));
      const profileId = JSON.parse(localStorage.getItem('log_in_data')).profiles[0].swipecast_profile_id;
      const randomNumber = Math.floor(Math.random() * 10000000000);
      const tid = process.env.REACT_APP_GOOGLE_ANALYTICS_TOKEN;
      new Promise((resolve, reject) => {
        $.ajax({
          method: 'POST',
          url: `https://www.google-analytics.com/collect`,
          data: `v=1&t=event&tid=${tid}&z=${randomNumber}&cid=${clientID}&ec=Approved%20project&ea=Approved%20project&el=${profileId}&ni=1&cd1=${clientID}&cd3=${profileId}`,
          success: resolve,
          error: reject
        });
      });
    }
  }
  //clicked: boolean, true/false. if the Notification has been clicked (true), we want it to visually
  //look different vs a clicked=false Notification. Just like on facebook.
  getStyles = clicked => {
    //these styles are common to Notifications, regardless of clicked=true/false
    let styles = {
      container: {
        position: 'relative',
        float: 'left',
        fontSize: '0.8125rem',
        height: '80px',
        width: '100%',
        borderBottomWidth: '1px',
        borderBottomColor: '#ddd',
        cursor: 'pointer',
        borderBottomStyle: 'solid',
        display: 'flex',
        flexDirection: 'row'
      },
      text_container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      },
      text: {
        textAlign: 'left',
        width: '100%',
        paddingRight: '20px',
        marginTop: '15px',
        lineHeight: '15px',
        letterSpacing: '1px',
        fontFamily: 'Avenir-Medium,Lato',
        fontWeight: 500,
        fontSize: '12px'
      },
      date: {
        left: '100px',
        height: '20px',
        paddingRight: '20px',
        letterSpacing: '1px',
        fontFamily: 'Avenir-Medium,Lato',
        fontWeight: 500
      },
      right_carat_container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '40px',
        justifyContent: 'center',
        alignItems: 'center'
      },

      image_container: {
        backgroundImage:
          'url(' + (this.props.notification.data.image_url ? this.props.notification.data.image_url : 'blank') + ')',
        position: 'relative',
        backgroundSize: 'contain',
        backgroundColor: '#ddd',
        height: '60px',
        margin: '10px',
        borderRadius: '40px',
        width: '60px'
      },

      profile_view_image: {
        position: 'relative',
        backgroundSize: 'contain',
        backgroundColor: '#ddd',
        height: '40px',
        margin: '10px',
        borderRadius: '40px',
        width: '40px',
        overflow: 'hidden'
      },

      right_carat: {
        right: '10px',
        height: '25px',
        width: '25px',
        top: '27px'
      },
      button_container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      },
      button: {
        width: '80px',
        padding: '2px',
        height: '35px'
      }
    };

    if (clicked) {
      styles.container.backgroundColor = '#f7f7f7';
      styles.right_carat.opacity = '0.5';
      styles.date.color = '#999';
      styles.heavy = {
        fontFamily: 'Avenir-Heavy,Lato',
        fontWeight: '700',
        color: '#555'
      };
      styles.light = {
        fontFamily: 'Avenir-Roman,Lato',
        fontWeight: '300',
        color: '#555'
      };
    } else {
      styles.right_carat.opacity = '1';
      styles.text.color = 'white';
      styles.date.color = '#555';
      styles.heavy = {
        fontFamily: 'Avenir-Black,Lato',
        fontWeight: '900',
        color: 'black'
      };
      styles.light = {
        fontFamily: 'Avenir-Medium,Lato',
        fontWeight: '500',
        color: 'black'
      };
    }

    return styles;
  };

  getButtons = () => {
    if (this.props.notification.notification_type === 'proposeConnection') {
      return [
        {
          type: 'solid',
          title: 'ACCEPT',
          onClick: () => {
            this.props.onClickAccept();
          }
        },
        {
          type: 'outline',
          title: 'DELETE',
          onClick: () => {
            this.props.onClickDelete();
          }
        }
      ];
    } else {
      return [];
    }
  };

  render() {
    // PORT-TODO: ADD BUTTONS
    const styles = this.getStyles(this.props.notification.clicked);
    const buttons = this.getButtons();
    const label = NotificationHelpers.notificationText(this.props.notification);

    return (
      <div
        style={styles.container}
        onClick={() => {
          this.props.clickOnElement('body_of_notification');
        }}
      >
        {this.props.notification.notification_type !== 'profileViewed' && <div style={styles.image_container} />}
        {this.props.notification.notification_type === 'profileViewed' && (
          <div style={styles.profile_view_image}>
            {this.props.notification.data.preview_users.map(user => (
              <img key={user.profile_image} src={user.profile_image} />
            ))}
          </div>
        )}
        <div style={styles.text_container}>
          <div style={styles.text}>
            {label &&
              label.map((block, index) => {
                return (
                  <span key={index} style={styles[block.font]}>
                    {block.text ? block.text : ''}
                  </span>
                );
              })}
          </div>
          <div style={styles.date}>
            {DateHelpers.timeStampToLabelFull(this.props.notification.created_at, true, true)}
          </div>
        </div>
        {buttons.length > 0 && (
          <div style={styles.button_container}>
            {buttons.map((button, index) => {
              if (button.type === 'solid') {
                return (
                  <div style={styles.button} key={index}>
                    <SolidButton
                      title={button.title}
                      onClick={() => {
                        this.props.clickOnElement('accept');
                      }}
                    />
                  </div>
                );
              } else {
                return (
                  <div style={styles.button} key={index}>
                    <OutlinedButton
                      type="hollow_gray"
                      title={button.title}
                      onClick={() => {
                        this.props.clickOnElement('delete');
                      }}
                      style={{
                        minWidth:
                          '50px' /* override the Component's minWidth: 150px so that the button is not too wide */
                      }}
                    />
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    );
  }
}

export default NotificationSnapshot;
