import queryString from 'query-string';
import get from 'lodash.get';

import Settings from 'helpers/settings';
import SCLocalStorage from 'classes/sc_localstorage';
import SCTrack from 'classes/sc_track';
import store from '../store/configureStore';
import { logout as logoutAction } from '../store/actions';
import ChatHelpers from './chat_helpers';
import ProfileHelpers from 'helpers/profile_helpers';
import UserHelpers from 'helpers/user_helpers';

const LocalStorage = new SCLocalStorage();
const $ = window.jQuery;

const AuthHelper = {
  SC_LOCAL_AUTH_TOKEN_KEY: 'sc-auth-token',
  SC_LOCAL_CURRENT_USER_KEY: 'log_in_data',

  is_agency: false,

  get logged_in() {
    return this.isLoggedIn();
  },

  get logged_in_profileID() {
    return this.currentUser().swipecast_user_id;
  },

  get logged_in_firstName() {
    return this.currentUser().first_name;
  },

  get logged_in_fullName() {
    const user = this.currentUser()
    return `${user.first_name} ${user.last_name}`;
  },

  get logged_in_email() {
    return this.currentUser().email;
  },

  get logged_in_lastName() {
    return this.currentUser().last_name;
  },

  get location() {
    return this.currentUser().location;
  },

  get phone_number_updated_at() {
    return this.currentUser().phone_number_updated_at;
  },

  get logged_in_profile_types() {
    const current_user = this.currentUser();
    return current_user.profiles ? current_user.profiles.map(user => user.profile_type) : null;
  },

  get logged_in_gender() {
    const current_user = this.currentUser();
    if (get(current_user, 'profiles[0].profile_type') !== 'Model') return null;
    return current_user.profiles[0].data.gender;
  },

  get logged_in_profile_img() {
    return this.currentUser().profile_image;
  },

  get logged_in_waitlisted() {
    return this.currentUser().waitlisted;
  },

  get is_admin() {
    return this.currentUser().admin;
  },

  get sc_assistant() {
    return this.currentUser().swipecast_user_id === ChatHelpers.assistantSwipecastUserId;
  },

  get intent_role() {
    const currentUser = this.currentUser();
    return currentUser.intent_role;
  },

  get canInviteToJob() {
    return !AuthHelper.logged_in_waitlisted && (AuthHelper.canCreateProject || AuthHelper.currentUser().has_postings);
  },

  // if a client was invited by talent using special booking offer, he should have a talent focused flow for 72h
  // with removed ability to post jobs and book other users
  get underOfferIdlePeriod() {
    if (!AuthHelper.currentUser()) return false;
    return AuthHelper.currentUser().offer_restrictions_applied;
  },

  // if a client was invited by talent using link-in-bio, he should have a talent focused flow for 72h
  // with removed ability to post jobs and book other users
  get underLibIdlePeriod() {
    if (!AuthHelper.currentUser()) return false;
    return AuthHelper.currentUser().lib_restrictions_applied;
  },

  get canCreateProject() {
    return !AuthHelper.underLibIdlePeriod && !AuthHelper.underOfferIdlePeriod && ProfileHelpers.hasClientProfile();
  },

  get canBookTalent() {
    return ProfileHelpers.hasClientProfile();
  },

  get bookable() {
    return UserHelpers.bookable(AuthHelper.currentUser());
  },

  // What if talent has disabled bookings via settings? He will not have a bookable profile
  get canCreateOffers() {
    return Settings.bookingOffersEnabled && !AuthHelper.logged_in_waitlisted && AuthHelper.hasTalentProfile;
  },

  get settings() {
    return this.currentUser().settings;
  },

  get hasClientProfile() {
    return ProfileHelpers.hasClientProfile();
  },

  get hasTalentProfile() {
    return ProfileHelpers.hasTalentProfile();
  },

  profileImgSubmitted() {
    const image = this.currentUser().profile_image;
    return !!image && image !== '';
  },

  setCurrentAuthToken(token) {
    LocalStorage.setAuthToken(token);
  },

  setCurrentLoggedInUser(user) {
    LocalStorage.setCurrentUser(user);
  },

  currentAuthToken() {
    return LocalStorage.fetchAuthToken();
  },

  isGuest() {
    return this.currentAuthToken() === '';
  },

  isLoggedIn() {
    return !this.isGuest();
  },

  logout() {
    this.setCurrentAuthToken('');
    this.setCurrentLoggedInUser('');
    store.store.dispatch(logoutAction());
  },

  currentUser() {
    const currentUser = LocalStorage.fetchCurrentUser();

    if (currentUser && currentUser.table) {
      return currentUser.table;
    }

    return currentUser;
  },

  processLoggedInData(userData) {
    this.setCurrentAuthToken(userData.auth_token);
    this.setCurrentLoggedInUser(JSON.stringify(userData.user));
    SCTrack.processLoggedInData(userData.user);
  },

  loadFromBackend() {
    return new Promise((resolve, reject) => {
      if (!AuthHelper.currentAuthToken()) {
        reject();
        return null;
      }

      $.ajax('/me', {
        success: res => {
          AuthHelper.setCurrentLoggedInUser(JSON.stringify(res.user));
          resolve();
        },
        error: reject
      });
    });
  },

  performLoginRedirect() {
    const q = queryString.parse(window.location.search);
    if (q.redirect_uri) window.location.replace(q.redirect_uri);
    else window.location.reload();
  },

  performLogin(params, callback, onError) {
    /**
     * IMPORTANT: Calling AuthHelper.logout() and using the absolute URL in
     * the login URL are mandatory here.
     * That's a double security measure to ensure that the token is not
     * sent to the backend when trying to login (assuming there's any in LS)
     * See: InitHelper.ajaxInit() for more info
     */
    AuthHelper.logout();

    $.ajax({
      method: 'POST',
      url: `${Settings.SWIPECAST_BACKEND}/login`,
      dataType: 'JSON',
      data: params,
      success: data => {
        AuthHelper.processLoggedInData(data);
        if (callback) callback(data);
      },
      error: err => {
        console.log(err.responseJSON);
        onError(err);
      }
    });
  },
  passwordResetEmail(email, callback, onError) {
    // Send ajax call to log in
    $.ajax({
      method: 'POST',
      url: '/reset_password',
      dataType: 'JSON',
      data: {
        email
      },
      success: data => {
        callback(data);
      },
      error: err => {
        const message = err.responseJSON ? err.responseJSON.message : 'Failed to send instructions';
        onError({ error: true, message });
      }
    });
  }
};

export default AuthHelper;
