import AuthHelper from 'helpers/auth_helper'

export const versions = {
  desktopSearchVersions: { v1: 1, v2: 2 },
  mobileSearchVersions: { v2: 2 },
  jobRateVersions: { v1: 1, v2: 2 },
}

const Settings = {
  getZoomMeetingsEnabled() {
    //return process.env.NODE_ENV === 'development' || AuthHelper.is_admin
    return !AuthHelper.logged_in_waitlisted
  },

  chatReceiptMessage: false,
  enableInitialSignature: process.env.NODE_ENV === 'development',
  newChatVideoLoading: true,
  bookingOffersEnabled: true,
  // signUpAdditionalStepsSkippable: process.env.NODE_ENV === 'development',
  signUpAdditionalStepsSkippable: false,
  // creditCardFeeNumeric: 0.036,
  // clientFee: '5%',
  // clientFeeNumeric: 0.05,
  creditCardFeeNumeric: 0.03,
  clientFee: '7%',
  clientFeeNumeric: 0.07,
  modelFee: '15%',
  minHoursForHourly: 4,
  desktopSearchVersion: versions.desktopSearchVersions.v2,
  mobileSearchVersion: versions.mobileSearchVersions.v2,
  jobRateVersion: versions.jobRateVersions.v2,
  uaBannerEnabled: true,
  appURL: 'https://apps.apple.com/app/apple-store/id988583579',
  unknownErrorMessage: 'Unknown error occurred. Please try again later or contact support@swipecast.com',
  support: {
    phoneNumber: '6469609833',
    phoneLabel: '(646) 960-9833',
    email: 'support@swipecast.com',
    whatsappURL: 'https://wa.me/16469609833',
  },
  offer: {
    minDayRate: 400,
    maxDayRate: 10000,
  },

  SWIPECAST_BACKEND: process.env.REACT_APP_SWIPECAST_BACKEND || 'https://swipecast-backend-staging.herokuapp.com',
  SWIPECAST_IMAGE_BACKEND: process.env.REACT_APP_SWIPECAST_IMAGE_BACKEND,
  STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  LOGGING: process.env.REACT_APP_LOGGING === 'true',
  SWIPECAST_PUSHER_APP_KEY: process.env.REACT_APP_SWIPECAST_PUSHER_APP_KEY || 'e7c5a3f1cd506431cf9f',
  SWIPECAST_PUSHER_APP_CLUSTER: process.env.REACT_APP_SWIPECAST_PUSHER_APP_CLUSTER || 'us2',
  SWIPECAST_ADMIN_URL: process.env.REACT_APP_ADMIN_URL || 'http://localhost:3002',
  GOOGLE_OAUTH_CLIENT_ID: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
  INSTA_APP_ID: process.env.REACT_APP_INSTAGRAM_APP_ID,
}

export default Settings
