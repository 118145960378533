import Button from 'components/reusable/button';
import SvgArrowLeftSolid from 'components/reusable/svg/svg_arrow_left_solid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Indent from 'components/reusable/indent';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';

function ZoomAgreementModalLeftPartition() {
  const history = useHistory();

  return (
    <Wrapper>
      <StyledButton type="semantic-wrapper" onClick={history.goBack}>
        <SvgArrowLeftSolid size={16} color={colors.light} />
      </StyledButton>

      <div>
        <Text color={colors.light} size={[{ size: 28, maxWidth: 768 }, { size: 40 }]} weight={500}>
          One Last Step
        </Text>
        <Indent top={[{ margin: 24, maxWidth: 768 }, { margin: 32 }]} />
        <Text color={colors.light} size={[{ size: 16, maxWidth: 768 }, { size: 18 }]}>
          Before starting your Zoom meeting, please make sure you have read and accept the following security terms.
          <br />
          <br />
          This invitation will also be sent to the other party by email.
        </Text>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 440px;
  height: 100%;
  display: flex;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  align-items: center;
  background: linear-gradient(-15deg, #00d0fe, #1768ff);
  padding: 48px;
  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    border-radius: 0;
    padding: 8px 16px 56px;
    width: unset;
    height: unset;
  }
`;

const StyledButton = styled(Button)`
  margin-bottom: 24px;
  width: 24px;
  height: 24px;
  align-self: flex-start;

  @media (min-width: 769px) {
    display: none;
  }
`;

export default ZoomAgreementModalLeftPartition;
