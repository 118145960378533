export const signUpStepsData = {
  profile: { field: 'user_type', form: 'signUpProfile' },
  intention: { field: 'intent_role', form: 'signUpIntention' },
  first_name: { field: 'first_name', form: 'signUpByEmail' },
  last_name: { field: 'last_name', form: 'signUpByEmail' },
  email: { field: 'email', form: 'signUpByEmail' },
  location: { field: 'location', form: 'signUpLocation' },
  oauth: { field: 'google_token_id', form: 'signUpOauth' },
  website: { field: 'website', form: 'signUpWork' },
  company: { field: 'organization', form: 'signUpWork' },
  jobTitle: { field: 'job_title', form: 'signUpWork' },
  ig: { field: 'instagram', form: 'signUpWork' },
  phone: { field: 'phone', form: 'signUpPhone' },
  password: { field: 'password', form: 'signUpByEmail' },
  referral: { field: 'referral_code', form: 'signUpReferral' },
  image: { field: 'image', form: 'sigUpImage' },
  imagePostpone: { field: 'imagePostpone', form: 'sigUpImage' }
};
