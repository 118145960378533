import { useCallback } from 'react';

import AuthHelper from 'helpers/auth_helper';
import { buildGa4ImageStepAttrs } from 'components/modals/v2/auth/sign_up_logo_modal/hooks/use_submit';
import SC_GA4, { actions } from 'classes/sc_ga4';
import { modalEnum } from 'components/modals/v2/modal';
import { useModalContext } from 'hooks/use_context'

const useNavigateToLogoStep = () => {
  const modalContext = useModalContext()

  const onShowSignUpLogoModal = useCallback(() => {
    const user = AuthHelper.currentUser();
    const avatarProvided = user.profile_image && user.profile_image != '';

    if (avatarProvided) {
      const ga4Params = buildGa4ImageStepAttrs(user, avatarProvided, true);
      SC_GA4.sendMetric(actions.userCreateFunnel.image, ga4Params);
      return modalContext.onCloseModal();
    }

    return modalContext.onOpenModal(modalEnum.signUpLogo);
  }, [modalContext.onOpenModal, modalContext.onCloseModal]);

  return onShowSignUpLogoModal;
};

export default useNavigateToLogoStep;