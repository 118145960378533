import React, { useCallback } from 'react'
import styled, { css } from 'styled-components';

import { colors } from 'helpers/colors';
import { getDimention } from 'helpers/style';

export const _getFontSize = ({ size }) => {
  const isResponsiveText = Array.isArray(size);
  if (!isResponsiveText) return `font-size: ${getDimention(size) || '1rem'};`;

  const medias = size
    .sort((x, y) => {
      if (!x.maxWidth || x.maxWidth > y.maxWidth) return -1;
      return 0;
    })
    .map(s => {
      if (!s.maxWidth) return `font-size: ${getDimention(s.size)};`;
      return `@media (max-width: ${getDimention(s.maxWidth)}) { font-size: ${getDimention(s.size)} };`;
    });

  return medias.join('');
};

const _getWordBreak = ({ lines, wordBreak }) => {
  if (wordBreak) return wordBreak;
  if (lines && lines > 1) return 'break-word';
  if (lines === 1) return 'break-all';
  return 'normal';
};

export const textStyles = css`
  ${_getFontSize};
  transform: translateY(0.05em);
  text-transform: ${({ $textTransform }) => $textTransform};
  text-align: ${({ textAlign }) => textAlign};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${colors.gray[700]};
  font-family: 'Gotham Pro', sans-serif;
  ${({ display }) => display && `display: ${display}`};
  ${({ weight }) => weight && `font-weight: ${weight}`};
  ${({ color }) => color && `color: ${color}`};
  word-break: ${_getWordBreak};
  ${({ height }) =>
    height &&
    css`
      height: ${height};
      min-height: ${height};
    `}

  ${({ lines }) =>
    lines &&
    css`
      width: 100%;
      overflow: auto; /* hidden does not work on safari but seems like auto does the job */
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${lines}; /* number of lines to show */
      line-clamp: ${lines};
      -webkit-box-orient: vertical;
    `}
`;

const Paragraph = styled.p`
  ${textStyles}
`;
const H1 = styled.h1`
  ${textStyles}
`;
const H2 = styled.h2`
  ${textStyles}
`;

const Text = ({
  wordBreak,
  lines,
  textAlign,
  textTransform,
  size,
  name,
  tag,
  weight = 400,
  className,
  children,
  color,
  height,
  lineHeight = '1.375em',
  letterSpacing = '0,005em',
  ...props
}) => {
  // overflow: hidden does not work on safari, so we have to disable scrolling programmatically
  const preventScroll = useCallback((e) => e.target.scrollTo({ top: 0, behavior: 'instant' }), [])

  const componentProps = {
    onScroll: lines ? preventScroll : undefined,
    wordBreak,
    size,
    weight,
    name,
    id: name,
    color,
    className,
    lineHeight,
    letterSpacing,
    lines,
    height,
    textAlign,
    $textTransform: textTransform
  };

  const Component = tag === 'h1' ? H1 : tag === 'h2' ? H2 : Paragraph;

  return (
    <Component {...componentProps} {...props}>
      {children}
    </Component>
  );
};

export default Text;
