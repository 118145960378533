import React from 'react'
import styled, { css } from 'styled-components'

import { colors } from 'helpers/colors'
import { getDimention } from 'helpers/style'
import Flex from 'components/reusable/flex'
import LoadingIndicator from 'components/reusable/loading/LoadingIndicator'

interface IButtonProps {
  loading?: boolean
  semanticType: 'reset' | 'button' | 'submit';
  component?: React.ElementType<any>;
  children?: string;
  onClick?: () => void;
  type: 'outline' | 'gray' | 'primary' | 'light-gray' | 'transparent' | 'semantic-wrapper' | 'gray-shadow';
  minSize?: boolean;
  width?: string;
  bgColor?: string;
  height?: number;
  borderColor?: string;
  borderWidth?: string;
  borderStyle?: string;
  color?: string;
  isDisabled?: boolean;
  size?: number | string
}

const ButtonComponent = styled('button')<any>`
  position: relative;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ height, minSize }) => minSize || height ? 0 : '10px'} 16px;
  border-radius: ${({ height }) =>
          height ? getDimention(height / 2) : '20px'};
  font-family: Gotham Pro;
  font-weight: 500;
  cursor: pointer;
  font-size: ${({ size }) => size};
  color: ${colors.light};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  ${({ width, minSize }) => !minSize && width && `width: ${width}`};
  ${({ height, minSize }) => !minSize && height && `height: ${height}`};
  ${({ btnType, bgColor, isDisabled }) => {
    if (btnType === 'outline') {
      return `
        color: ${colors.gray[400]};
        border: 1px solid ${colors.gray[400]};
        background-color: transparent;
      `
    } else if (btnType === 'gray') {
      return `background-color: ${colors.gray[400]};`
    } else if (btnType === 'light-gray') {
      return `background-color: ${colors.gray[200]};`
    } else if (btnType === 'gray-shadow') {
      return (`
        background-color: ${colors.gray[100]};
        box-shadow: inset 0px 0px 2px rgba(31, 30, 30, 0.16);
        color: ${colors.gray[600]}`)
    } else if (btnType === 'primary') {
      return css`
        background-color: ${colors.primary[500]};

        &:disabled {
          opacity: 1;
          background-color: ${colors.gray[200]}
        }
      `
    } else if (btnType === 'transparent') {
      return `background-color: transparent;`
    } else if (btnType === 'semantic-wrapper') {
      return css`
        background-color: transparent;
        height: fit-content;
        width: ${({ width = 'fit-content' }) => width};
        padding: 0;
      `
    } else if (bgColor)
      return `background-color: ${bgColor};`
  }}
  ${({ borderColor, borderStyle = 'solid', borderWidth = '1px' }) => borderColor && css`
    border: ${borderWidth} ${borderStyle} ${borderColor};
  `};
  ${({ color }) => color && `color: ${color};`}
`

const Button = ({
                  loading = false,
                  semanticType = 'button',
                  color,
                  borderWidth,
                  borderStyle,
                  borderColor,
                  minSize,
                  type: btnType = undefined,
                  size = '1rem',
                  children,
                  width,
                  height,
                  onClick,
                  isDisabled,
                  bgColor,
                  ...props
                }: IButtonProps) => {
  const buttonProps = {
    isDisabled,
    type: semanticType,
    disabled: isDisabled,
    onClick,
    height,
    size,
    width,
    borderColor,
    borderWidth,
    borderStyle,
    btnType,
    children,
    color,
    bgColor,
    minSize,
    ...props,
  }

  return <ButtonComponent {...buttonProps}>
    {loading
      ? <Flex gap={12}>
          <StyledLoading color={colors.light} size={14} />
          <LoadingContentWrapper>
            {children}
          </LoadingContentWrapper>
        </Flex>

      : children}
  </ButtonComponent>
}

const LoadingContentWrapper = styled(Flex)`
  filter: blur(1px);
`

const StyledLoading = styled(LoadingIndicator)`
  position: absolute;
  left: calc(50% - 0.5em);
  margin: 0;
`

export default Button
